<div id="accountPageWrapper" class="bg-f5f5f5 ff-roboto">
  <div class="w-100 p-30 pl-sm-20 pr-sm-20 p-20-sm">
    <div class="d-flex-row w-100">
      <h1 class="h1-dark fs-22">Products</h1>
    </div>
    <div class="w-100 mt-20 brds-4px d-flex-col">
      <div class="w-100 d-flex-row fx-wrap-sm">
        <div class="profile-action-box d-flex-col jc-center ml-auto">
          <button
            (click)="openAddProductDialog({})"
            mat-raised-button
            color="primary"
            class="tt-upper mb-15-i"
          >
            Add Product
          </button>
          <button
            (click)="openAddTaxDialog()"
            mat-raised-button
            color="primary"
            class="tt-upper mb-15-i"
          >
            Add/Edit Tax Information
          </button>
        </div>
      </div>
    </div>
    <div class="w-100 mt-20 b-s1-00012 brds-4px p-25 d-flex-col bg-white">
      <div class="d-inline-block w-100-i">
        <div class="responsive-table">
          <table id="streamItems" class="w-100">
            <thead>
              <tr>
                <th class="pl-10-i">image</th>
                <th class="pl-10-i">Name</th>
                <th class="pl-10-i">Category</th>
                <th class="pl-10-i">Price (Cents)</th>
                <th class="pl-10-i">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of productList">
                <td class="text-0006 fs-14 lh-24">
                  <img
                    width="40px"
                    height="30px"
                    src="{{ row.displayImage }}"
                  />
                </td>
                <td class="text-0006 fs-14 lh-24">{{ row.displayName }}</td>
                <td class="text-0006 fs-14 lh-24">
                  {{ row.displayCategoryName }}
                </td>
                <td class="text-0006 fs-14 lh-24">
                  {{ row.displayPrice }}
                </td>
                <td>
                  <button
                    mat-icon-button
                    [matMenuTriggerFor]="menu"
                    aria-label="Example icon-button with a menu"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <!-- <button mat-menu-item>Edit</button> -->
                    <button mat-menu-item (click)="openAddProductDialog(row)">
                      Edit
                    </button>
                    <button
                      mat-menu-item
                      (click)="openDeleteProductDialog(row)"
                    >
                      Delete
                    </button>
                  </mat-menu>
                </td>
              </tr>
              <tr *ngIf="!productList.length">
                <td class="text-0006 fs-14 lh-24"></td>
                <td class="text-0006 fs-14 lh-24 tc">No Products Available</td>
                <td class="text-0006 fs-14 lh-24"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
