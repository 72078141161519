<div class="message-input-container">
  <div class="d-flex-row ff-roboto" style="width: 100%; padding: 8px">
    <mat-form-field appearance="outline" class="w-100">
      <mat-progress-bar
        mode="indeterminate"
        class="color-primary pstn-absolute-i-b0-l0 h-1px-i"
        [class.hidden]="!sendingConversation"
      ></mat-progress-bar>
      <input
        [disabled]="sendingConversation"
        [(ngModel)]="conversationText"
        (ngModelChange)="onConversationTextChange($event)"
        matInput
        placeholder="Type your message"
        (keydown.enter)="addConversation()"
      />
      <button
        [disabled]="disableSendButton"
        matSuffix
        type="submit"
        mat-mini-fab
        color="primary"
        (click)="addConversation()"
      >
        <mat-icon>send</mat-icon>
      </button>
    </mat-form-field>
    <button
      *ngIf="isCreator"
      mat-button
      color="primary"
      (click)="openAddProject()"
    >
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
