import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'message-container',
  styleUrls: ['../../../assets/css/message/message-container.component.css'],
  templateUrl: '../../views/message/message-container.component.html',
})
export class MessageContainerComponent implements OnInit, OnDestroy {
  selectedMessageId: string;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  selectMessageId(messageId: string) {
    this.selectedMessageId = messageId;
  }
}
