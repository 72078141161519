import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import _ from 'lodash';
import { NotificationStatusEnum } from 'src/app/enums/notification.enum';

import { IProduct } from 'src/app/models/product.model';
import { IUserInfo } from 'src/app/models/user-info.model';
import { AccountService, AuthenticationService } from 'src/app/services';
import { NotificationService } from 'src/app/services/notification.service';

interface IProductDisplayDialogData {
  productId: string;
  creator: IUserInfo;
  retailer: IUserInfo;
}

@Component({
  selector: 'product-display-dialog',
  templateUrl: '../../views/common/product-display.dialog.component.html',
  styleUrls: [
    '../../../assets/css/common/product-display.dialog.component.css',
  ],
})
export class ProductDisplayDialogComponent implements OnInit {
  productId: string;
  retailer: IUserInfo;
  creator: IUserInfo;
  inProgress: boolean;
  productImagePath: string;
  showActions: boolean;
  productName: string;

  constructor(
    public dialogRef: MatDialogRef<ProductDisplayDialogComponent>,
    private snackBar: MatSnackBar,
    private notificationService: NotificationService,
    private accountService: AccountService,
    private authenticationService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA)
    public data: IProductDisplayDialogData
  ) {
    this.productId = data.productId;
    this.retailer = data.retailer;
    this.creator = data.creator;
    this.inProgress = false;
    this.showActions = false;
  }

  ngOnInit(): void {
    if (this.authenticationService.isCreator) {
      this.fetchInvitations();
    }

    this.accountService
      .getProductDetailsById(this.productId)
      .subscribe((res) => {
        this.productName = _.get(res, 'item_data.name');
        this.productImagePath = _.get(
          _.first(res?.images),
          'object.image_data.url',
          './assets/images/thumbnail-default.jpg'
        );
      });
  }

  fetchInvitations() {
    this.notificationService
      .getSpecificNotifications(NotificationStatusEnum.INVITED)
      .toPromise()
      .then((res: any) => {
        if (!res.isSuccess) {
          throw Error('Something went wrong!');
        }
        // an invitation with the product id
        const invitationNotificationList = _.get(res, 'data', []);
        this.showActions = !!_.find(
          invitationNotificationList,
          ({ productId }) => productId === this.productId
        );
      })
      .catch((err) => {
        const errorDetail = err?.error?.error || err.error || err.statusText;
        this.snackBar.open(errorDetail, 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
      });
  }

  acceptInvitation() {
    this.inProgress = true;
    this.notificationService
      .createNotification({
        retailerId: this.retailer._id,
        creatorId: this.creator._id,
        productId: this.productId,
        status: NotificationStatusEnum.ACCEPTED,
      })
      .toPromise()
      .then((res) => {
        if (res.isSuccess) {
          this.snackBar.open(
            'Invitation accepted, you can message now.',
            'close',
            {
              duration: 10000,
              panelClass: ['mat-success-bg'],
            }
          );
        }
        this.dialogRef.close();
      })
      .catch((err: HttpErrorResponse) => {
        const errorDetail = err.error.error || err.error || err.statusText;
        this.snackBar.open(errorDetail, 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  rejectInvitation() {
    this.inProgress = true;
    this.notificationService
      .createNotification({
        retailerId: this.retailer._id,
        creatorId: this.creator._id,
        productId: this.productId,
        status: NotificationStatusEnum.REJECTED,
      })
      .toPromise()
      .then((res) => {
        if (res.isSuccess) {
          this.snackBar.open('Invitation rejected.', 'close', {
            duration: 10000,
            panelClass: ['mat-success-bg'],
          });
        }
        this.dialogRef.close();
      })
      .catch((err: HttpErrorResponse) => {
        const errorDetail = err.error.error || err.error || err.statusText;
        this.snackBar.open(errorDetail, 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  cancelOperation() {
    this.dialogRef.close();
  }
}
