<div class="p-20">
  <div class="container">
    <message-list
      [selectMessageId]="selectMessageId.bind(this)"
      class="message-container"
    ></message-list>
    <conversation-list
      [selectedMessageId]="selectedMessageId"
      class="conversation-container"
    ></conversation-list>
  </div>
</div>
