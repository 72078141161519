export enum CreatorProjectTypeEnum {
  SHOWCASE_PROJECT = 'SHOWCASE_PROJECT',
  RETAILER_PROJECT = 'RETAILER_PROJECT',
}

export enum ProjectStatusEnum {
  DRAFT_PROJECT = 'DRAFT_PROJECT', // project is created, not published yet
  PUBLISHED_PROJECT = 'PUBLISHED_PROJECT', // a showcase project, published in the creator profile and available in the home and search
  DELIVERED_PROJECT = 'DELIVERED_PROJECT', // project is delivered to the retailer but payment is not completed yet
  PAID_PROJECT = 'PAID_PROJECT', // the retailer made the payment for the project
}

export interface IProject {
  id: string;
  _id: string;
  name: string;
  price: number;
  description: string;
  creatorId: string;
  retailerId: string;
  messageId: string;
  type:
    | CreatorProjectTypeEnum.RETAILER_PROJECT
    | CreatorProjectTypeEnum.SHOWCASE_PROJECT;
  status:
    | ProjectStatusEnum.DELIVERED_PROJECT
    | ProjectStatusEnum.PUBLISHED_PROJECT
    | ProjectStatusEnum.DELIVERED_PROJECT
    | ProjectStatusEnum.PAID_PROJECT;
  thumbnail: string;
  video: number;
  videoMetadata: any;
  likeCount: number;
  productId: string;
  referenceProjectId: string;
  created: string;
  creator: {
    _id: string;
    domain: string;
    email: string;
    firstName: string;
    lastName: string;
    profilePictureUrl: string;
    roles: Array<string>;
    storeBannerUrl: string;
    storeName: string;
    logoUrl: string;
  };
}
