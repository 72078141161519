import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import _ from 'lodash';

import { AppConfig } from '../app.config';
import { IConsumer, IRetailer } from '../models';
import { IResetPasswordRequest } from '../models/reset-password-request.model';
import { IUserInfo } from '../models/user-info.model';
import { IProduct, IProductImage } from '../models/product.model';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  protected apiServer = AppConfig.settings.apiServer;
  protected localStorageKeys = AppConfig.settings.localStorage.keys;

  constructor(private http: HttpClient) {}

  registerRetailer(retailer: IRetailer) {
    return this.http
      .post<any>(`${this.apiServer.basepath}/users/register`, retailer)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  registerConsumer(consumer: IConsumer) {
    return this.http
      .post<any>(`${this.apiServer.basepath}/users/register`, consumer)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  verifyEmail(activationCode: string) {
    const verificationApiUrl = `${
      this.apiServer.basepath
    }/users/${encodeURIComponent('verify-email')}`;
    return this.http
      .post<any>(verificationApiUrl, { token: `${activationCode}` })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  updateProductNatures(userId: string, productNatureIds: string[]) {
    const updateProductNaureApiUrl = `${this.apiServer.basepath}/users/${userId}/product-natures`;
    return this.http
      .patch<any>(updateProductNaureApiUrl, {
        productNatureIds: productNatureIds,
      })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getUserInfoByUserId(userId: string): Observable<IUserInfo> {
    return this.http
      .get<IUserInfo>(`${this.apiServer.basepath}/users/${userId}`)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  sendPasswordResetLink(userEmailAddress: string) {
    const verificationApiUrl = `${this.apiServer.basepath}/users/forgot-password`;
    return this.http
      .post<any>(verificationApiUrl, { email: `${userEmailAddress}` })
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  resetPassword(data: IResetPasswordRequest) {
    const verificationApiUrl = `${this.apiServer.basepath}/users/reset-password`;
    return this.http.post<any>(verificationApiUrl, data).pipe(
      map((res) => {
        return res;
      })
    );
  }

  getProductList(userId: string) {
    return this.http
      .get<any>(`${this.apiServer.basepath}/users/${userId}/items`)
      .pipe(
        map((res: IProduct) => {
          return res;
        })
      );
  }

  getProductDetailsList(userId: string) {
    return this.http
      .get<any>(`${this.apiServer.basepath}/items/list/${userId}`)
      .pipe(
        map((res: IProduct) => {
          return res;
        })
      );
  }

  getProductDetailsById(productId: string) {
    return this.http
      .get<any>(
        `${this.apiServer.basepath}/items/${productId}/expanded-details`
      )
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  getProductImage(imageId: string) {
    return this.http
      .get<any>(`${this.apiServer.basepath}/items/${imageId}`)
      .pipe(
        map((res: IProductImage) => {
          return res;
        })
      );
  }
}
