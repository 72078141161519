<div
  class="pstn-absolute-t0-l0 w-100 h-100 ta-center zi-9999999 bg-primary-0082"
  [class.hidden]="isAuthorized"
  *ngIf="subscriptionRequired"
>
  <span
    class="text-white fs-18 fw-400 mw-50 pstn-relative top-30 d-inline-block subscribe-res"
  >
    This is an exclusive session. Please login to get access to this session
  </span>
  <br />
  <a
    href="/login?returnUrl={{ returnUrl }}"
    mat-raised-button
    color="primary"
    class="tt-upper pstn-relative top-30 d-inline-block mt-35-i fw-400-i"
    >LOGIN NOW</a
  >
</div>
<div
  class="pstn-absolute-t0-l0 w-100 h-100 ta-center zi-9999999 bg-primary-0082"
  [class.hidden]="!isAuthorized"
  *ngIf="subscriptionRequired"
>
  <span
    class="text-white fs-18 fw-400 mw-50 pstn-relative top-30 d-inline-block subscribe-res"
  >
    This is an exclusive session. Please subscribe to get access to this session
  </span>
  <br />
  <a
    (click)="openSubscriptionDialog()"
    mat-raised-button
    color="primary"
    class="tt-upper pstn-relative top-30 d-inline-block mt-35-i fw-400-i mt-res"
    >SUBSCRIBE NOW</a
  >
</div>
<div class="d-flex-row w-100 pstn-relative" id="streamCardContentContainer">
  <div
    *ngIf="displaySessionThumbImage"
    class="d-flex-col w-100 h-100 pstn-absolute-t0-l0 of-hidden"
  >
    <img
      [src]="session?.streamThumbnailUrl"
      id="streamingThumbImg"
      class="obj-ft-contain obj-pstn-50-50 m-auto w-100 h-100 mt-2px"
    />
  </div>
  <div
    class="d-flex-col w-100 h-100 pstn-absolute-t0-l0"
    [class.hidden]="!displayPreRecordedStream"
  >
    <video
      class="w-100 h-100"
      controls
      autoplay
      muted
      playsinline
      src="{{ recordedVideoUrl }}"
    ></video>
  </div>
  <div
    id="liveStreamVideoObjectContainer"
    class="d-flex-col w-100 h-100 pstn-absolute-t0-l0"
    [class.hidden]="!displayRed5ProSubscription"
  >
    <video
      id="{{ streamElementId }}"
      class="w-100 h-100"
      controls
      autoplay
      muted
      playsinline
      src="{{ recordedVideoUrl }}"
    ></video>
  </div>
  <div
    class="d-flex-col w-100 h-100 pstn-absolute-t0-l0"
    [class.hidden]="!displayOfflineSubscription"
  >
    <video
      id="video-player"
      controls
      autoplay
      muted
      playsinline
      preload="metadata"
      class="video-js vjs-default-skin w-100-i h-100-i"
    ></video>
  </div>
</div>
