import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash';
import * as moment from 'moment';
import { UNSEEN_CONVERSATION_FETCH_INTERVAL } from 'src/app/app.config';

import { IMessageResponse } from 'src/app/models/message.mode';
import { ConversationService } from 'src/app/services/conversation.service';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'message-item',
  styleUrls: ['../../../assets/css/message/message-item.component.css'],
  templateUrl: '../../views/message/message-item.component.html',
})
export class MessageItemComponent implements OnInit, OnDestroy {
  @Input() messageId: string;
  messageItem: IMessageResponse & {
    formattedPath: string;
  };
  timeoutId;
  unseenMessageCount: number;

  constructor(
    private messageService: MessageService,
    private conversationService: ConversationService
  ) {
    this.unseenMessageCount = 0;
  }

  ngOnInit(): void {
    this.fetchMessage();
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeoutId);
  }

  fetchMessage() {
    this.messageService.getMessageById(this.messageId).subscribe((res) => {
      const path = _.get(res, 'retailer.image');
      const defaultPath = './assets/images/default-profile-pic.jpg';
      this.messageItem = {
        ...res,
        formattedPath: _.isEmpty(path) || !_.size(path) ? defaultPath : path,
      };
      this.fetchUnseenMessageCount();
    });
  }

  getFormattedDate(dateStr: string) {
    return moment(dateStr).format('MMM - YY');
  }

  fetchUnseenMessageCount() {
    this.conversationService
      .getUnseenConversationCountByMessageId(this.messageId)
      .subscribe((count) => {
        this.unseenMessageCount = count;
        this.timeoutId = setTimeout(() => {
          this.fetchUnseenMessageCount();
        }, UNSEEN_CONVERSATION_FETCH_INTERVAL);
      });
  }
}
