import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import _ from 'lodash';
import { Subscription } from 'rxjs';
import { ConversationTypeEnum } from 'src/app/enums/conversation-enum';

import { IConversation } from 'src/app/models/conversession.model';
import { IMessageResponse } from 'src/app/models/message.mode';
import { AccountService, AuthenticationService } from 'src/app/services';
import { ConversationService } from 'src/app/services/conversation.service';
import { MessageService } from 'src/app/services/message.service';
import {
  PROJECT_DISPLAY_TYPE_ENUM,
  ProjectDisplayDialogComponent,
} from '../common/project-display.dialog.component';
import { FETCH_REALTIME_CONVERSATIONS } from 'src/app/app.config';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'conversation-list',
  styleUrls: ['../../../assets/css/message/conversation-list.component.css'],
  templateUrl: '../../views/message/conversation-list.component.html',
})
export class ConversationListComponent implements OnInit, OnDestroy, OnChanges {
  @Input() selectedMessageId: string;
  @ViewChild('conversationListScrollContainer')
  private conversationListScrollContainer!: ElementRef;
  messageInfo: IMessageResponse;

  defaultProfilePhoto = './assets/images/default-profile-pic.jpg';

  conversationItems: IConversation[];
  senderImage: string;
  receiverImage: string;

  showConversations: boolean;

  conversationSubscription: Subscription;
  scrollDownTimeoutId;
  fetchConversationsTimeoutId;

  constructor(
    private conversationService: ConversationService,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private projectService: ProjectService,
    private router: Router,
    private accountService: AccountService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.conversationItems = [];
    this.showConversations = false;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes['selectedMessageId']) {
      return;
    }

    if (_.isEmpty(this.selectedMessageId)) {
      return;
    }

    if (this.conversationSubscription) {
      this.conversationSubscription.unsubscribe();
    }

    if (this.scrollDownTimeoutId) {
      clearTimeout(this.scrollDownTimeoutId);
    }

    if (this.fetchConversationsTimeoutId) {
      clearTimeout(this.fetchConversationsTimeoutId);
    }

    this.getMessageInfo();
    this.getConversationList();
  }

  ngOnDestroy(): void {}

  getConversationList() {
    if (_.isEmpty(this.selectedMessageId)) {
      return;
    }

    this.conversationSubscription = this.conversationService
      .getConversationsByMessageId(this.selectedMessageId)
      .subscribe((res) => {
        if (_.size(res) === _.size(this.conversationItems)) {
          this.getConversationListWithDelay();
          return;
        }

        this.conversationItems = _.map(res, (conversationItem) => {
          const isReceived =
            conversationItem.receiverId ===
            this.authenticationService.currentUserId;
          conversationItem.isReceived = isReceived;
          conversationItem.thumbnail =
            (isReceived
              ? _.get(conversationItem, 'receiver.image')
              : _.get(conversationItem, 'sender.image')) ??
            this.defaultProfilePhoto;
          conversationItem.isPlainMessage =
            conversationItem.type === ConversationTypeEnum.PLAIN_MESSAGE;
          return conversationItem;
        });
        this.showConversations = !!_.size(this.conversationItems);
        // wait till the conversations are rendered in the canvas and then scroll down
        this.scrollDownTimeoutId = setTimeout(() => {
          try {
            this.conversationListScrollContainer.nativeElement.scrollTop =
              this.conversationListScrollContainer.nativeElement.scrollHeight;
          } catch (err) {
            console.error('Scroll error:', err);
          }
        }, 10);

        this.getConversationListWithDelay();
      });
  }

  getConversationListWithDelay(duration = FETCH_REALTIME_CONVERSATIONS) {
    // fetch for new conversations after a while
    this.fetchConversationsTimeoutId = setTimeout(() => {
      this.getConversationList();
    }, duration);
  }

  getMessageInfo() {
    this.messageService
      .getMessageById(this.selectedMessageId)
      .subscribe((res) => {
        this.messageInfo = res;
      });
  }

  onClickProfile() {
    if (!this.messageInfo) {
      return;
    }

    // get receiver
    const currentUserId = this.authenticationService.currentUserId;
    const { creatorId, retailerId } = this.messageInfo;
    let receiverId = currentUserId === creatorId ? retailerId : creatorId;
    this.accountService.getUserInfoByUserId(receiverId).subscribe((res) => {
      this.router.navigate([`/shops/${res.domain}`]);
    });
  }

  openProjectDetails() {
    this.projectService
      .getProjectById(this.messageInfo.projectId)
      .subscribe((projectInfo) => {
        const dialogRef = this.dialog.open(ProjectDisplayDialogComponent, {
          data: {
            projectId: this.messageInfo.projectId,
            actionType:
              _.get(projectInfo, 'status') === 'DRAFT_PROJECT'
                ? PROJECT_DISPLAY_TYPE_ENUM.RETAILER_FOR_PAYMENT
                : PROJECT_DISPLAY_TYPE_ENUM.RETAILER_FOR_CREATE_STREAM,
          },
        });
        dialogRef.afterClosed().toPromise();
      });
  }
}
