import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IItemCategory } from 'src/app/models';
import { IProject } from 'src/app/models/project.model';
import { IRecentlyAiredSchedule } from 'src/app/models/recently-aired-items.model';
import { IRetailerInfo } from 'src/app/models/retailer-info.model';
import { IUpcomingSchedule } from 'src/app/models/up-coming-items.model';
import { ItemService } from 'src/app/services';
import { ShopService } from 'src/app/services/shop.service';

@Component({
  selector: 'shops-store-front',
  templateUrl: '../../views/shops/shops-store-front.component.html',
  styles: [
    `
      #storeLogoContainer img {
        height: 124px;
        width: 124px;
        object-fit: contain;
      }
    `,
  ],
})
export class ShopsStoreFrontComponent implements OnInit, OnDestroy {
  subscriptions = new Subscription();
  retailerDomain: string;

  loadingItems: boolean = true;

  retailerInfo: IRetailerInfo;
  productNatures: any[];
  retailerProductNatures: any[];
  raItems: any[] = [];
  ucItems: any[] = [];
  projectItems: Array<IProject> = [];

  startIndex: number = 0;
  endIndex: number = 3;

  constructor(
    private activatedRoute: ActivatedRoute,
    private shopService: ShopService,
    public itemService: ItemService
  ) {}

  ngOnInit(): void {
    this.retailerDomain =
      this.activatedRoute.snapshot.paramMap.get('storename');

    this.itemService.categories().subscribe((productNatures: any) => {
      this.shopService
        .loadRetailerInfoByDomain(this.retailerDomain)
        .toPromise()
        .then((retailerInfo: any) => {
          this.loadingItems = false;
          this.productNatures = productNatures;
          this.retailerInfo = retailerInfo;
          this.retailerProductNatures = this.productNatures.filter(
            (item: IItemCategory) => {
              return retailerInfo.productNatures.some((id) => id == item.id);
            }
          );
        });
    });

    this.loadRecentlyAiredSchedules();
    this.loadUpcommingSchedules();
    this.loadProjects();
  }

  loadRecentlyAiredSchedules() {
    this.subscriptions.add(
      this.shopService
        .allSchedulesInRecentlyAiredByDomain(this.retailerDomain)
        .subscribe((res: IRecentlyAiredSchedule[]) => {
          this.raItems = res;
        })
    );
  }

  loadUpcommingSchedules() {
    this.subscriptions.add(
      this.shopService
        .allSchedulesInUpcomingByDomain(this.retailerDomain)
        .subscribe((res: IUpcomingSchedule[]) => {
          this.ucItems = res;
        })
    );
  }

  loadProjects() {
    this.subscriptions.add(
      this.shopService
        .allProjectsByCreatorDomain(this.retailerDomain)
        .subscribe((res: IProject[]) => {
          this.projectItems = res;
        })
    );
  }

  combineBackendApiUrl(relativePath: string) {
    if (!relativePath) return '/assets/images/store_shop_banner.png';
    return relativePath;
  }

  onStoreBannerImageError(event$: any, defaultUrl: string) {
    event$.target.src = defaultUrl;
  }

  displayNextItems() {
    if (this.endIndex + 1 > this.projectItems.length) {
      this.startIndex = 0;
      this.endIndex = 3;
      return;
    }

    this.startIndex = this.startIndex + 1;
    this.endIndex = this.endIndex + 1;
  }

  displayPrevItems() {
    if (this.startIndex - 1 < 0) {
      this.startIndex = this.projectItems.length - 4;
      this.endIndex = this.projectItems.length - 1;
      return;
    }

    this.startIndex = this.startIndex - 1;
    this.endIndex = this.endIndex - 1;
  }

  indexBetweenRange(index) {
    if (index >= this.startIndex && index <= this.endIndex) {
      return true;
    }

    return false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
