import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { IProject } from '../models/project.model';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  protected apiServer = AppConfig.settings.apiServer;
  protected localStorageKeys = AppConfig.settings.localStorage.keys;

  constructor(
    private http: HttpClient,
    private authService: AuthenticationService
  ) {}

  getProjectById(projectId: string): Observable<IProject> {
    const apiUrl = `${this.apiServer.basepath}/project/${projectId}`;
    return this.http.get(apiUrl).pipe(
      map((project: IProject) => {
        return project;
      })
    );
  }

  getProjects(): Observable<IProject[]> {
    const creatorId = this.authService.currentUserId;
    const apiUrl = `${this.apiServer.basepath}/projects/${creatorId}`;
    return this.http.get(apiUrl).pipe(
      map((projectList: IProject[]) => {
        return projectList;
      })
    );
  }

  createProject(projectFormData: FormData): Observable<any> {
    return this.http
      .post<any>(`${this.apiServer.basepath}/projects`, projectFormData)
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  updateProject(projectId: string, projectFormData: FormData): Observable<any> {
    return this.http
      .patch<any>(
        `${this.apiServer.basepath}/projects/update/${projectId}`,
        projectFormData
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  makePayment(projectId: string, paymentToken: string) {
    return this.http
      .post<any>(`${this.apiServer.basepath}/project/payment/${projectId}`, {
        paymentToken,
      })
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }
}
