import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import _ from 'lodash';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ItemService } from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-add-item-dialog',
  templateUrl: '../../views/item/add-item.component.html',
})
export class AddItemDialogComponent implements OnInit {
  itemForm: FormGroup;
  inProgress: boolean = false;
  categories: Array<{ id: string; category: string }> = [];
  itemImage: File;
  existingTaxData;
  disableTaxInfo: Boolean = true;
  product;
  formTitle: string;

  name: FormControl; // = new FormControl('', [Validators.required]);
  price: FormControl; // = new FormControl('', [Validators.required]);
  categoryId: FormControl; // = new FormControl('', [Validators.required]);
  description: FormControl; // = new FormControl('', [Validators.required]);
  enableTax: FormControl; // = new FormControl(false, [Validators.required]);

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddItemDialogComponent>,
    public itemService: ItemService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.product = data;
  }

  ngOnInit(): void {
    this.name = new FormControl(_.get(this.product, 'item_data.name') || '', [
      Validators.required,
    ]);
    this.price = new FormControl(
      _.get(
        _.first(_.get(this.product, 'item_data.variations')),
        'item_variation_data.price_money.amount'
      ) || '',
      [Validators.required]
    );
    this.categoryId = new FormControl(
      _.get(this.product, 'category.object.id') || '',
      [Validators.required]
    );
    this.description = new FormControl(
      _.get(this.product, 'item_data.description') || '',
      [Validators.required]
    );
    this.enableTax = new FormControl(
      !!_.size(_.get(this.product, 'item_data.tax_ids')) || false,
      [Validators.required]
    );

    this.formTitle =
      !!this.product && !!this.product.id ? 'Edit Product' : 'Add Product';

    this.itemForm = this.formBuilder.group({
      name: this.name,
      price: this.price,
      description: this.description,
      categoryId: this.categoryId,
      itemImage: this.itemImage,
      enableTax: this.enableTax,
    });

    this.itemService.categories().subscribe((categories) => {
      this.categories = categories;
    });

    this.itemService.getTaxData().subscribe((taxData) => {
      this.existingTaxData = taxData;
      this.disableTaxInfo = !taxData;
    });
  }

  onItemImageChange($event) {
    if ($event.target.files.length > 0) {
      const file = $event.target.files[0];
      this.itemImage = file;
    }
  }

  onSubmit() {
    if (this.itemForm.invalid) {
      return;
    }

    this.inProgress = true;

    const formData = new FormData();

    formData.append('name', this.name.value);
    formData.append('price', this.price.value);
    formData.append('description', this.description.value);
    formData.append('categoryId', this.categoryId.value);
    formData.append('itemImage', this.itemImage);
    formData.append('enableTax', this.enableTax.value);
    formData.append('id', !!this.product.id ? this.product.id : '');

    this.itemService
      .createItem(formData)
      .toPromise()
      .then((res: any) => {
        this.inProgress = false;
        this.snackBar.open('Item information updated', 'Close', {
          duration: 10000,
          panelClass: ['mat-success-bg'],
        });
        this.dialogRef.close();
      })
      .catch((err: HttpErrorResponse) => {
        this.inProgress = false;
        const errorDetail = err.error.error || err.error;
        this.snackBar.open(errorDetail, 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
