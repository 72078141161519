<div class="container">
  <div *ngIf="messageInfo" class="top-section">
    <div class="top-text">{{ messageInfo.name }}</div>
    <div class="top-text profile-text" (click)="onClickProfile()">
      View Profile
    </div>
  </div>
  <div #conversationListScrollContainer class="conversion-items-container">
    <span *ngIf="showConversations">
      <div
        class="conversation-item"
        *ngFor="let conversationItem of conversationItems"
        [ngStyle]="{
          'flex-direction': conversationItem.isReceived ? 'row' : 'row-reverse'
        }"
      >
        <img
          class="conversation-thumbnail"
          src="{{ conversationItem.thumbnail }}"
          alt="profile"
        />
        <div *ngIf="conversationItem.isPlainMessage" class="conversation-text">
          {{ conversationItem.content }}
        </div>
        <div
          *ngIf="!conversationItem.isPlainMessage"
          class="conversation-text fw-600 d-flex"
        >
          {{ messageInfo.name }}
          <mat-icon
            (click)="openProjectDetails()"
            class="fs-20 pl-5"
            style="cursor: pointer"
            >open_in_new</mat-icon
          >
        </div>
      </div>
    </span>
  </div>
  <conversation-input
    *ngIf="selectedMessageId"
    [selectedMessageId]="selectedMessageId"
    [getConversationList]="getConversationList.bind(this)"
  ></conversation-input>
</div>
