<div class="container">
  <div class="top-section">
    <div class="title p-5">Inbox</div>
    <div class="p-5 pt-10 pb-10">
      <input
        [(ngModel)]="messageSearchText"
        (ngModelChange)="onMessageSearchTextChange($event)"
        matInput
        class="input"
        placeholder="Search"
      />
    </div>
  </div>
  <div *ngIf="showMessageList" class="message-items-container">
    <div
      *ngFor="let messageItem of filteredMessageItems"
      class="messageItem"
      (click)="onSelectMessage(messageItem)"
      [ngStyle]="{
        background:
          messageItem._id === selectedMessageId ? '#eae2f5' : undefined
      }"
    >
      <message-item [messageId]="messageItem._id"></message-item>
    </div>
  </div>
</div>
