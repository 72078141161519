import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { IItemCategory } from '../models';
import { IItemDetail } from '../models/item-detail.model';
import { IItem } from '../models/item.model';
import { taxDetail } from '../models/tax-detail.model';

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  protected apiServer = AppConfig.settings.apiServer;
  protected localStorageKeys = AppConfig.settings.localStorage.keys;

  constructor(private http: HttpClient) {}

  categories(): Observable<any> {
    return this.http
      .get<IItemCategory[]>(`${this.apiServer.basepath}/items/categories`)
      .pipe(
        map((res: any) => {
          return res.categories;
        })
      );
  }

  allItemsByUserId(userId: string): Observable<IItem[]> {
    return this.http
      .get<IItem[]>(`${this.apiServer.basepath}/users/${userId}/items`)
      .pipe(
        map((res: IItem[]) => {
          return res;
        })
      );
  }

  getItemsList(userId: string) {
    return this.http
      .get<IItem[]>(`${this.apiServer.basepath}/items/list/${userId}`)
      .pipe(
        map((res: IItem[]) => {
          return res;
        })
      );
  }

  getItemNameById(itemId: string): Observable<string> {
    const apiUrl = `${this.apiServer.basepath}/items/${itemId}/name`;
    return this.http.get<string>(apiUrl).pipe(
      map((res: string) => {
        return res;
      })
    );
  }

  getItemDetailById(itemId: string): Observable<IItemDetail> {
    const apiUrl = `${this.apiServer.basepath}/items/${itemId}/details`;
    return this.http.get<IItemDetail>(apiUrl).pipe(
      map((res: IItemDetail) => {
        return res;
      })
    );
  }

  getItemTaxById(taxId: any, retailerDomain: string): Observable<taxDetail> {
    const apiUrl = `${this.apiServer.basepath}/items/tax-details/${taxId}/domain/${retailerDomain}`;
    return this.http.get<taxDetail>(apiUrl).pipe(
      map((res: taxDetail) => {
        return res;
      })
    );
  }

  getTaxData() {
    const apiUrl = `${this.apiServer.basepath}/users/tax`;
    return this.http.get<taxDetail>(apiUrl).pipe(
      map((res: taxDetail) => {
        return res;
      })
    );
  }

  createItem(formData: FormData): Observable<any> {
    const apiUrl = `${this.apiServer.basepath}/users/items`;
    return this.http.post(apiUrl, formData).pipe(
      map((res: IItemDetail) => {
        return res;
      })
    );
  }

  createTax(taxData): Observable<any> {
    const apiUrl = `${this.apiServer.basepath}/users/tax`;
    return this.http.post(apiUrl, taxData).pipe(
      map((res: IItemDetail) => {
        return res;
      })
    );
  }

  deleteItem(itemId: string): Observable<IItemDetail> {
    const apiUrl = `${this.apiServer.basepath}/users/items/${itemId}`;
    return this.http.get(apiUrl).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  createImage(imageFormData: FormData): Observable<any> {
    const apiUrl = `${this.apiServer.basepath}/users/images`;
    return this.http.post<any>(apiUrl, imageFormData).pipe(
      map((res: any) => {
        return res;
      })
    );
  }
}
