import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import _ from 'lodash';
import * as moment from 'moment';

import { StreamStatusEnum } from 'src/app/enums/stream-status.enum';
import { SessionComponentService } from 'src/app/services/components/session-compnent.service';
import { ScheduleService } from 'src/app/services/schedule.service';
import { CancelSessionDialogComponent } from '../../common/session/cancel-session-dialog.component';
import { DeleteSessionDialogComponent } from '../../common/session/delete-session-dialog.component';
import { AuthenticationService } from 'src/app/services';
import { RetailerStoreService } from 'src/app/services/retailer-store.service';
import { ISchedule } from 'src/app/models/schedule.model';

@Component({
  selector: 'session-card-header',
  templateUrl:
    '../../../views/sessions/card/session-stream-card-header.component.html',
})
export class SessionStreamCardHeaderComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  onAir: boolean = false;
  publisingStream: boolean = false;

  isPending: boolean;
  isComplete: boolean;

  sessionStartTime: Date;
  sessionDuration: number;
  sessionId: string;
  session: ISchedule;
  sessionTitle: string;
  storeName: string;
  isExclusive: boolean;

  isPreRecordedStream: boolean;

  conversationEnabled: boolean = true;

  subscriptions: Subscription = new Subscription();

  showStartButton = false;
  showStopButton = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    public sessionManager: SessionComponentService,
    private scheduleService: ScheduleService,
    private authenticationService: AuthenticationService,
    private retailerStoreService: RetailerStoreService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.storeName = this.activatedRoute.parent.snapshot.paramMap.get('domain');
    this.sessionId = this.activatedRoute.snapshot.paramMap.get('sessionId');
    this.scheduleService
      .getScheduleById(this.sessionId)
      .subscribe((session) => {
        this.session = session;
        this.isPreRecordedStream = !_.isEmpty(
          _.get(session, 'advertizeVideoUrl')
        );

        if (this.isPreRecordedStream) {
          this.handlePreRecordedInit();
        } else {
          this.updateUiActionStatus(true, false);
          this.handleRed5ProInit();
        }
      });
  }

  ngAfterViewInit(): void {
    if (!this.isPreRecordedStream) {
      this.handleRed5ProAfterViewInit();
    }
  }

  ngOnDestroy(): void {
    if (!this.isPreRecordedStream) {
      this.handleRed5ProOnDestroy();
    }
  }

  updateStreamStatus(status = this.session.status) {
    this.onAir = _.parseInt(status) === StreamStatusEnum.IN_PROGRESS;
    this.isPending = _.parseInt(status) === StreamStatusEnum.PENDING;
    this.isComplete = _.parseInt(status) === StreamStatusEnum.CLOSED;
  }

  updateUiActionStatus(showStartButton, showStopButton) {
    this.showStartButton = showStartButton;
    this.showStopButton = showStopButton;
  }

  handlePreRecordedInit() {
    this.updateStreamStatus();
    this.updateUiActionStatus(this.isPending, this.onAir);
  }

  handleRed5ProInit() {
    this.subscriptions.add(
      this.sessionManager.status.subscribe((status) => {
        this.updateStreamStatus(status);
      })
    );
    this.subscriptions.add(
      this.sessionManager.sessionDuration.subscribe((duration: number) => {
        this.sessionDuration = duration;
      })
    );
    this.subscriptions.add(
      this.sessionManager.sessionStartTime.subscribe((startTime: any) => {
        this.sessionStartTime = startTime;
      })
    );
    this.subscriptions.add(
      this.sessionManager.publisingStream.subscribe((publishing: boolean) => {
        this.publisingStream = publishing;
        this.updateUiActionStatus(!this.publisingStream, this.publisingStream);
      })
    );
    this.subscriptions.add(
      this.sessionManager.conversationEnabled.subscribe((enabled: boolean) => {
        this.conversationEnabled = enabled;
      })
    );
    this.subscriptions.add(
      this.sessionManager.sessionId.subscribe((id: string) => {
        this.sessionId = id;
      })
    );
    this.subscriptions.add(
      this.sessionManager.isExclusive.subscribe((res: boolean) => {
        this.isExclusive = res;
      })
    );
    this.subscriptions.add(
      this.sessionManager.sessionTitle.subscribe((res: string) => {
        this.sessionTitle = res;
      })
    );
  }

  handleRed5ProAfterViewInit() {
    this.sessionManager.configureConversationContentPlacement.emit(true);
  }

  handleRed5ProOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  startStream(): void {
    // for red5pro stream, load the stream
    if (!this.isPreRecordedStream) {
      this.sessionManager.startStream.emit(true);
    } else {
      // for pre-recorded stream, start the stream by updating status and reload the page
      const userId = this.authenticationService.currentUserId;
      this.retailerStoreService
        .updateStartStreamingStatus(userId, this.sessionId, new Date().toJSON())
        .toPromise()
        .then((res) => {
          if (res.isSuccess) {
            location.reload();
          }
        });
    }
  }

  stopStream(): void {
    if (this.isPreRecordedStream) {
      const userId = this.authenticationService.currentUserId;
      this.retailerStoreService
        .updateStopStreamingStatus(userId, this.session.id, new Date().toJSON())
        .subscribe((res) => {
          location.reload();
        });
    } else {
      this.sessionManager.stopStream.emit(true);
    }
  }

  onConversationEnabledChanged($event) {
    const enabled = $event.checked;
    this.scheduleService
      .toggleExclusiveSessionEnableChatOption(this.sessionId, enabled)
      .toPromise()
      .then((res: any) => {
        if (res.isSuccess.toLowerCase() === 'true') {
          this.sessionManager.conversationEnabled.emit(enabled);
        }
      });
  }

  deleteStream() {
    const dialogRef = this.dialog.open(DeleteSessionDialogComponent, {
      data: { sessionTitle: this.sessionTitle },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.delete) {
        this.snackBar.open(`Deleting session ${this.sessionTitle}`, 'Action', {
          duration: undefined,
        });
        this.scheduleService
          .deleteScheduleById(this.sessionId)
          .toPromise()
          .then((res: any) => {
            if (res.isSuccess && res.isSuccess == 'true') {
              this.snackBar.open(`Deleted`, 'Action', { duration: 2000 });
              window.location.href = `/store/${this.storeName}/sessions`;
            } else {
              this.snackBar.open(res.message, 'Close', {
                duration: 10000,
                panelClass: ['mat-error-bg'],
              });
            }
          });
      }
    });
  }

  cancelStream() {
    const dialogRef = this.dialog.open(CancelSessionDialogComponent, {
      data: { sessionTitle: this.sessionTitle },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.cancel) {
        this.snackBar.open(`Canceling session ${this.sessionTitle}`, 'Action', {
          duration: undefined,
        });
        this.scheduleService
          .cancelScheduleById(this.sessionId)
          .toPromise()
          .then((res: any) => {
            if (res.isSuccess && res.isSuccess == 'true') {
              this.snackBar.open(`Canceled`, 'Action', { duration: 2000 });
              window.location.href = `/store/${this.storeName}/sessions`;
            } else {
              this.snackBar.open(res.message, 'Close', {
                duration: 10000,
                panelClass: ['mat-error-bg'],
              });
            }
          });
      }
    });
  }
}
