import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AccountService,
  AuthenticationService,
  ItemService,
} from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import _ from 'lodash';
import { RetailerStoreService } from 'src/app/services/retailer-store.service';
import { HttpErrorResponse } from '@angular/common/http';

import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AppConfig } from 'src/app/app.config';
import { ScheduleService } from 'src/app/services/schedule.service';

export const CREATE_STREAM_FROM_PROJECT = 'CREATE_STREAM_FROM_PROJECT';

interface CreateStreamDialogData {
  actionType: string;
  projectId: string;
}

@Component({
  selector: 'create-stream-dialog',
  templateUrl: '../../views/store/create-stream.dialog.component.html',
  styleUrls: ['../../../assets/css/store/create-stream.dialog.component.css'],
})
export class CreateStreamDialogComponent implements OnInit {
  public Editor = ClassicEditor;

  thumbnailPicture: File;
  thumbnailImageFileError: string;
  video = File;
  videoIsInvalid = false;

  inProgress: boolean = false;

  createStreamForm: FormGroup;

  streamType = new FormControl('showcase', [Validators.required]);
  streamTitle = new FormControl('', [Validators.required]);
  scheduleDate = new FormControl('', [Validators.required]);
  startTime = new FormControl('', [Validators.required]);

  subscriptionPrice = new FormControl('', []);

  streamThumbnail: File;
  streamThumbnailIsInvalid: boolean = false;
  isAssetsRequired: boolean = true;

  actionType: string;
  projectId: string;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<CreateStreamDialogComponent>,
    public itemService: ItemService,
    public authService: AuthenticationService,
    public accountService: AccountService,
    private scheduleService: ScheduleService,
    public retailerStoreService: RetailerStoreService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public dialogData: CreateStreamDialogData
  ) {
    this.createStreamForm = this.formBuilder.group({
      streamType: this.streamType,
      streamTitle: this.streamTitle,
      scheduleDate: this.scheduleDate,
      startTime: this.startTime,
      streamThumbnail: new FormControl('', []),
    });
  }

  ngOnInit(): void {
    const { actionType, projectId } = this.dialogData;
    this.actionType = actionType;
    this.projectId = projectId;

    this.isAssetsRequired =
      _.isEmpty(this.projectId) ||
      this.actionType !== CREATE_STREAM_FROM_PROJECT;

    if (!this.isAssetsRequired) {
      this.createStreamForm.controls['streamThumbnail'].setValidators([]);
    }
  }

  getErrorMessage(fieldKey: string) {
    if ((this.streamType.value as string).indexOf('exclusive') > -1) {
      if (!this.subscriptionPrice.value) {
        this.subscriptionPrice.setErrors({ required: true });
      }
    }

    switch (fieldKey) {
      case 'streamType':
        if (this.streamType.hasError('required'))
          return 'Stream type is required';
        break;
      case 'streamTitle':
        if (this.streamTitle.hasError('required'))
          return 'Stream title is required';
        break;
      case 'scheduleDate':
        if (this.scheduleDate.hasError('required'))
          return 'Schedule date is required';
        break;
      case 'startTime':
        if (this.startTime.hasError('required'))
          return 'Start time is required';
        break;
      case 'subscriptionPrice':
        if (this.subscriptionPrice.hasError('required'))
          return 'Subscription price is requred';
        break;
    }
  }

  onStreamThumbnailChange($event) {
    this.thumbnailImageFileError = '';

    if ($event.target.files.length > 0) {
      const file = $event.target.files[0];

      this.streamThumbnailIsInvalid = false;

      const maxSize = AppConfig.settings.appConfig.imageFileMaxSize;
      const fileSize = file.size / 1024;
      if (fileSize > maxSize) {
        this.thumbnailImageFileError = `Thumbnail picture file size can not exceed more than ${maxSize}KB`;
        return;
      }

      this.streamThumbnail = file;
    } else {
      this.streamThumbnailIsInvalid = true;
    }
  }

  onVideoChange($event) {
    if (_.size($event.target.files)) {
      const file = $event.target.files[0];
      this.video = file;
    } else {
      this.videoIsInvalid = true;
    }
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    console.log('On Submit');
    if (!_.isEmpty(this.projectId)) {
      this.createStreamWithoutAssets();
    } else {
      this.createStreamWithAssets();
    }
  }

  createStreamWithoutAssets() {
    this.inProgress = true;

    const startTime = new Date(
      new Date(this.scheduleDate.value).toDateString() +
        ' ' +
        this.startTime.value
    ).toJSON();
    this.scheduleService
      .createScheduleByProjectId({
        projectId: this.projectId,
        title: this.streamTitle.value,
        streamType: this.streamType.value,
        amount: (
          Number.parseFloat(this.subscriptionPrice.value) * 100
        ).toString(),
        startTime,
        streamDuration: '10',
      })
      .subscribe((res) => {
        this.inProgress = false;
        if (res.isSuccess) {
          this.snackBar.open('New stream schedule is created', 'close', {
            duration: 10000,
            panelClass: ['mat-success-bg'],
          });
          this.dialogRef.close({ created: true });
        } else {
          this.snackBar.open('Something went wrong!', 'close', {
            duration: 10000,
            panelClass: ['mat-error-bg'],
          });
          this.dialogRef.close();
        }
      });
  }

  createStreamWithAssets() {
    this.streamThumbnailIsInvalid = false;
    if (!this.streamThumbnail) {
      this.streamThumbnailIsInvalid = true;
      return;
    }

    const userId = this.authService.currentUserId;
    this.inProgress = true;

    const formData = new FormData();
    const startTime = new Date(
      new Date(this.scheduleDate.value).toDateString() +
        ' ' +
        this.startTime.value
    ).toJSON();

    formData.append('title', this.streamTitle.value);
    formData.append('type', this.streamType.value);
    formData.append('startTime', startTime);
    formData.append('streamThumbnail', this.streamThumbnail);
    formData.append('streamDuration', '10');

    if ((this.streamType.value as string).indexOf('exclusive') > -1) {
      formData.append(
        'amount',
        (Number.parseFloat(this.subscriptionPrice.value) * 100).toString()
      );
    }

    if (!!this.video) {
      formData.append('advertizeVideo', this.video as unknown as Blob);
    }

    this.retailerStoreService
      .createStream(userId, formData)
      .toPromise()
      .then((res: any) => {
        this.inProgress = false;
        this.snackBar.open('New stream schedule is created', 'close', {
          duration: 10000,
          panelClass: ['mat-success-bg'],
        });
        this.dialogRef.close({ created: true, streamId: res.data.streamId });
      })
      .catch((err: HttpErrorResponse) => {
        this.inProgress = false;
        const errorDetail = err.error.error || err.error || err.statusText;
        this.snackBar.open(errorDetail, 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
      });
  }
}
