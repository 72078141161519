import { RTCPublisher } from 'red5pro-webrtc-sdk';
import { AppConfig } from 'src/app/app.config';

export class Red5ProPublisher {
  public static publisher: RTCPublisher;

  public static start = async (streamName: string) => {
    try {
      Red5ProPublisher.publisher = new RTCPublisher();
      Red5ProPublisher.publisher.on('*', Red5ProPublisher.handlePublisherEvent);
      await Red5ProPublisher.publisher.init({
        protocol: AppConfig.settings.red5pro.protocol,
        port: AppConfig.settings.red5pro.port,
        host: AppConfig.settings.red5pro.host,
        app: AppConfig.settings.red5pro.applicationName,
        streamName: streamName,
        rtcConfiguration: {
          iceServers: [{ urls: 'stun:stun2.l.google.com:19302' }],
          iceCandidatePoolSize: 2,
          bundlePolicy: 'max-bundle',
        },
        streamMode: 'record',
        mediaElementId: 'red5pro-publisher',
        bandwidth: {
          audio: 56,
          video: 512,
        },
        clearMediaOnUnpublish: true,
        mediaConstraints: {
          audio: true,
          video: {
            width: {
              exact: 1280,
            },
            height: {
              exact: 720,
            },
            frameRate: {
              min: 8,
              max: 24,
            },
          },
        },
      });
      await Red5ProPublisher.publisher.publish();
    } catch (e) {
      throw e;
    }
  };

  public static stop = async () => {
    Red5ProPublisher.publisher
      .unpublish()
      .then(function () {})
      .catch(function (error) {});
  };

  private static handlePublisherEvent = (event: any) => {
    console.log(`pet: ${event.type}`);
    switch (event.type) {
      case 'Connect.Failure':
      case 'Unpublish.Success':
        var mediaStream = event.publisher.getMediaStream();
        Red5ProPublisher.resetMediaStream(mediaStream);
        break;
      case 'Publisher.Connection.Closed':
        // Red5ProPublisher.reconect();
        break;
    }
  };
  private static resetMediaStream = (mediaStream: any) => {
    var tracks = mediaStream.getTracks();
    tracks.forEach((track) => track.stop());
  };
  private static reconect = async () => {
    await Red5ProPublisher.publisher.publish();
  };
}
