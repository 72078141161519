<div class="mat-card w-100 h-100 pstn-relative" id="conversessionCard">
  <div id="conversessionCardHeader" class="mat-card-header w-100">
    <h1 class="mat-card-title m-0-i text-0087 fs-18 ff-roboto fw-500 lh-28">
      Live Chat
    </h1>
  </div>
  <div
    id="conversessionContainer"
    class="mat-card-content pstn-relative mb-0-i"
  >
    <p
      *ngIf="!isExclusive"
      [class.hidden]="onAir || conversationList.length > 0"
      id="chattingDisabledMsgContainer"
      class="ta-center text-0006 fs-12 lh-16 fw-400 ff-roboto hidden-sm"
    >
      Chatting is disabled until live stream starts
    </p>

    <div
      *ngIf="isExclusive && subscriptionRequired"
      [class.hidden]="conversationList.length > 0"
      class="hidden-sm"
    >
      <p
        [class.hidden]="isAuthorized"
        id="chattingDisabledMsgContainer"
        class="ta-center text-0006 fs-12 lh-16 fw-400 ff-roboto hidden-sm"
      >
        <span
          >This is an exclusive session. Please login to get access to this
          session.</span
        >
        <br />
        <a
          href="/login?returnUrl={{ windowUrl }}"
          mat-raised-button
          color="primary"
          class="tt-upper mt-20-i fw-400-i"
          >LOGIN NOW</a
        >
      </p>
      <p
        [class.hidden]="!isAuthorized"
        id="chattingDisabledMsgContainer"
        class="ta-center text-0006 fs-12 lh-16 fw-400 ff-roboto hidden-sm"
      >
        <span
          >Only the subscribed users will be able to view and participate in the
          conversation.</span
        >

        <a
          mat-raised-button
          color="primary"
          class="tt-upper mt-20-i fw-400-i"
          (click)="openSubscriptionDialog()"
          >SUBSCRIBE NOW</a
        >
      </p>
    </div>
    <div
      id="conversation-items-wrapper"
      class="d-flex-col p-20"
      *ngIf="conversationList.length > 0"
    >
      <div
        class="conversation-item-container pstn-relative pl-35 ff-roboto mb-15"
        *ngFor="let cnvItem of conversationList"
      >
        <mat-icon
          *ngIf="!hasProfilePic(cnvItem.user)"
          class="pstn-absolute-t0-l0 b-s1 brds-100"
          >account_circle
        </mat-icon>
        <img
          *ngIf="hasProfilePic(cnvItem.user)"
          class="pstn-absolute-t0-l0 b-s1 brds-100 h-24px w-24px obj-ft-cover mt-5 ml-5"
          src="{{ getProfilePicUrl(cnvItem.user) }}"
          onerror="this.src='./assets/images/default-profile-pic.jpg'"
        />
        <p class="profile-name d-block fs-13 fw-500 lh-24 m-0-i">
          <span *ngIf="!isRetailerComment(cnvItem.user)"
            >{{ cnvItem.user.firstName }} {{ cnvItem.user.lastName }}</span
          >
          <span *ngIf="isRetailerComment(cnvItem.user)">
            <span class="store-name-chips-in-chat">{{
              cnvItem.user.storeName
            }}</span>
            {{ cnvItem.user.firstName }} {{ cnvItem.user.lastName }}
          </span>
        </p>
        <p class="d-block fs-12 fw-400 lh-16 m-0-i">{{ cnvItem.content }}</p>
      </div>
    </div>
  </div>
  <div
    id="conversessionFooter"
    *ngIf="isAuthorized"
    class="mat-card-actions mat-elevation-z1 w-100 pstn-absolute-b0-l0 pstn-sm-relative"
  >
    <div class="pstn-relative w-100">
      <div
        *ngIf="!conversationEnabled"
        [class.hidden]="!isExclusive"
        class="chat-iniput-overlay"
        (click)="showChatInputDisabledNotification()"
      ></div>
      <div
        *ngIf="!onAir"
        [class.hidden]="isExclusive"
        class="chat-iniput-overlay"
        (click)="showChatInputDisabledNotification()"
      ></div>
      <div class="d-flex-row ff-roboto">
        <span class="text-0006 fs-12 lh-16 fw-400">Join the conversation</span>
        <mat-icon
          [inline]="true"
          appearance="outline"
          class="material-icons-outlined text-primary fs-16-i lh-16-i fw-400 ml-5 mr-5"
          >info
        </mat-icon>
        <span class="text-primary fs-12 lh-16 fw-400 crsr-pointer"
          >Chat Rules</span
        >
      </div>
      <div class="d-flex-row ff-roboto">
        <form
          name="commentForm"
          [formGroup]="commentForm"
          (ngSubmit)="postComment()"
        ></form>
        <mat-form-field
          appearance="outline"
          class="w-100 send-conversession-inputs"
        >
          <mat-progress-bar
            mode="indeterminate"
            class="color-primary pstn-absolute-i-b0-l0 h-1px-i"
            [class.hidden]="!postingComment"
          ></mat-progress-bar>
          <input
            matInput
            placeholder="Type your message"
            [(ngModel)]="postCommentContent"
            (keyup.enter)="postComment()"
          />
          <button
            matSuffix
            type="submit"
            (click)="postComment()"
            mat-mini-fab
            color="primary"
            aria-label="Post your comment"
          >
            <mat-icon>send</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div
    id="conversessionFooter"
    *ngIf="!isAuthorized"
    class="mat-card-actions mat-elevation-z1 w-100 pstn-absolute-b0-l0 ta-center pb-20-i pt-20i"
  >
    <p
      [class.hidden]="isExclusive"
      class="fs-16 text-0006 lh-24 fw-400 ff-roboto"
    >
      Ask questions or share your experience by joining the conversation
    </p>
    <p
      [class.hidden]="!isExclusive"
      class="fs-16 text-0006 lh-24 fw-400 ff-roboto"
    >
      Only the subscribed users will be able to view and participate in the
      conversation.
    </p>
    <a
      mat-raised-button
      [class.hidden]="isExclusive"
      color="primary"
      class="tt-upper"
      href="/login?returnUrl={{ windowUrl }}"
      >Log In</a
    >
  </div>
</div>
