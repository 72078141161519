import { Component, Input, OnInit } from '@angular/core';
import _ from 'lodash';

import { ConversationTypeEnum } from 'src/app/enums/conversation-enum';
import { RoleEnum } from 'src/app/enums/role.enum';
import { IMessageResponse } from 'src/app/models/message.mode';
import { AuthenticationService } from 'src/app/services';
import { ConversationService } from 'src/app/services/conversation.service';
import { MessageService } from 'src/app/services/message.service';
import { MatDialog } from '@angular/material/dialog';
import { SendProjectDialog } from './send-project.dialog.component';

@Component({
  selector: 'conversation-input',
  styleUrls: ['../../../assets/css/message/conversation-input.component.css'],
  templateUrl: '../../views/message/conversation-input.component.html',
})
export class ConversationInput implements OnInit {
  @Input() selectedMessageId: string;
  @Input() getConversationList: () => void;

  messageInfo: IMessageResponse;
  conversationText: string;
  sendingConversation: boolean;
  disableSendButton: boolean;
  isCreator: boolean;

  constructor(
    private messageService: MessageService,
    private authenticationService: AuthenticationService,
    private conversationService: ConversationService,
    public sendProjectDialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.conversationText = '';
    this.disableSendButton = true;
    this.sendingConversation = false;
    this.isCreator = this.authenticationService.isCreator === RoleEnum.creator;

    this.getMessageInfo();
  }

  getMessageInfo() {
    this.messageService
      .getMessageById(this.selectedMessageId)
      .subscribe((res) => {
        this.messageInfo = res;
      });
  }

  onConversationTextChange(newText: string) {
    this.conversationText = newText;
    if (_.size(this.conversationText)) {
      this.disableSendButton = false;
    }
  }

  addConversation() {
    const creatorId = _.get(this.messageInfo, 'creatorId');
    const retailerId = _.get(this.messageInfo, 'retailerId');

    if (
      _.isEmpty(creatorId) ||
      _.isEmpty(retailerId) ||
      _.isEmpty(this.conversationText)
    ) {
      console.warn('CreatorId or retailerId or conversationText is missing');
      return;
    }

    this.sendingConversation = true;
    this.disableSendButton = true;

    let senderId = '';
    let receiverId = '';

    if (this.authenticationService.currentUserId === creatorId) {
      senderId = creatorId;
      receiverId = retailerId;
    } else {
      senderId = retailerId;
      receiverId = creatorId;
    }

    this.conversationService
      .addConversation({
        messageId: this.selectedMessageId,
        senderId,
        receiverId,
        type: ConversationTypeEnum.PLAIN_MESSAGE,
        content: this.conversationText,
      })
      .subscribe((res) => {
        this.sendingConversation = false;
        this.disableSendButton = false;
        this.conversationText = '';
        this.getConversationList();
      });
  }

  openAddProject() {
    const dialogRef = this.sendProjectDialog.open(SendProjectDialog, {
      data: this.selectedMessageId,
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.created) {
        // or redirect to the same page
        // this.loadProjectList();
      }
    });
  }
}
