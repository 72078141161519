<div class="mw-580px-i mnw-320px">
  <label *ngIf="projectInfo" class="p3-dark-fwm mb-15 d-block text-black">{{
    projectInfo.name
  }}</label>
  <div class="">
    <div class="d-block w-100">
      <div id="card-container"></div>
    </div>
  </div>
  <div
    class="mat-dialog-actions pstn-relative m-0-24-neg p-0-24-i bt-s1-i bc-00012-i mt-15"
  >
    <mat-spinner
      diameter="25"
      class="color-primary pstn-absolute t24 l4"
      [class.hidden]="!inProgress"
    >
    </mat-spinner>
    <button
      mat-button
      class="tt-upper text-primary ml-auto-i"
      (click)="closeDialog()"
      disabled="{{ inProgress }}"
    >
      <span>Cancel</span>
    </button>
    <button
      mat-raised-button
      color="primary"
      type="submit"
      class="tt-upper ml-0-i ml-10"
      disabled="{{ inProgress }}"
      (click)="makePayment()"
    >
      <span>Pay</span>
    </button>
  </div>
</div>
