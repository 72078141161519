import {
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services';
import { SessionDirective } from 'src/app/directives/session.directive';
import { RoleEnum } from 'src/app/enums/role.enum';
import { PublicViewSessionComponent } from './public-view-session-component';
import { SessionComponentService } from 'src/app/services/components/session-compnent.service';
import { RetailerViewSessionComponent } from './retailer-view-session.component';

@Component({
  selector: 'session',
  template: '<ng-template sessionHost></ng-template>',
  styleUrls: ['../../../assets/css/session/session.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SessionComponent implements OnInit, OnDestroy {
  @ViewChild(SessionDirective, { static: true }) sessionHost!: SessionDirective;

  pvsComponent: any = PublicViewSessionComponent;

  rvsComponent: any = RetailerViewSessionComponent;

  urlSegments: string[];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private sessionManager: SessionComponentService,
    private router: Router
  ) {
    this.urlSegments = this.router.url.split('/').filter(function (item) {
      return item;
    });
  }

  ngOnInit(): void {
    if (this.urlSegments.indexOf('shops') > -1)
      return this.loadConsumerViewComponent();

    if (this.urlSegments.indexOf('store') > -1)
      return this.loadRetailerViewComponent();
  }

  ngOnDestroy(): void {}

  private loadConsumerViewComponent(): void {
    this.sessionManager.isConsumerView = true;
    this.sessionManager.isRetailerView = false;

    if (
      this.urlSegments.indexOf('shops') > -1 &&
      this.urlSegments.indexOf('sessions') > -1 &&
      this.urlSegments.indexOf('exclusive') > -1
    ) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          this.pvsComponent
        );
      const viewContainerRef = this.sessionHost.viewContainerRef;
      viewContainerRef.clear();
      const componentRef =
        viewContainerRef.createComponent<any>(componentFactory);

      this.sessionManager.isExclusive.emit(true);
      this.sessionManager.subscriptionRequired.emit(true);
      return;
    }

    this.sessionManager.isExclusive.emit(false);
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(this.pvsComponent);
    const viewContainerRef = this.sessionHost.viewContainerRef;
    viewContainerRef.clear();
    const componentRef =
      viewContainerRef.createComponent<any>(componentFactory);
  }

  private loadRetailerViewComponent(): void {
    this.sessionManager.isConsumerView = false;
    this.sessionManager.isRetailerView = true;
    const userRole = this.authenticationService.currentUserRole;
    if (userRole === RoleEnum.retailer) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          this.rvsComponent
        );
      const viewContainerRef = this.sessionHost.viewContainerRef;
      viewContainerRef.clear();
      const componentRef =
        viewContainerRef.createComponent<any>(componentFactory);
      return;
    }
  }
}
