<div *ngIf="messageItem" class="d-flex">
  <div class="pl-5">
    <img
      src="{{ messageItem.formattedPath }}"
      alt="thumbnail"
      class="messageThumbnail"
    />
  </div>
  <div class="messageMetadata">
    <div class="message-item-title">{{ messageItem.name }}</div>
    <div
      class="message-item-status"
      [ngStyle]="{
        'font-weight': unseenMessageCount ? '600' : '400'
      }"
    >
      {{ messageItem.statusMessage }}
    </div>
    <div class="message-item-date">
      {{ getFormattedDate(messageItem.createdAt) }}
    </div>
  </div>
</div>
