import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import _ from 'lodash';
import { StreamStatusEnum } from 'src/app/enums/stream-status.enum';
import { IItemCategory } from 'src/app/models';
import { CreatorProjectTypeEnum, IProject } from 'src/app/models/project.model';
import { ISearchResultDetail } from 'src/app/models/search-result-detail.model';
import { AuthenticationService, ItemService } from 'src/app/services';
import { SearchService } from 'src/app/services/search-service';
import {
  PROJECT_DISPLAY_TYPE_ENUM,
  ProjectDisplayDialogComponent,
} from '../common/project-display.dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'search-page',
  templateUrl: '../../views/search/search.component.html',
})
export class SearchComponent implements OnInit, OnDestroy {
  searchKeyword: string;
  searchResult: ISearchResultDetail;
  loadingResult: boolean = true;
  productNatures: IItemCategory[];
  private subscriptions = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private itemService: ItemService,
    private datePipe: DatePipe,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.activatedRoute.queryParams.subscribe((params) => {
        this.searchKeyword = params['q'];
        this.loadSearchResult(this.searchKeyword);
      })
    );
  }

  loadSearchResult(searchKeyword: string) {
    this.itemService.categories().subscribe((productNatures: any) => {
      this.searchService
        .searchShopsScheduleBy(searchKeyword)
        .toPromise()
        .then((shopsandschedues) => {
          this.productNatures = productNatures;
          this.searchResult = shopsandschedues;
          this.loadingResult = false;
        });
    });
  }

  combineBackendApiUrl(relativePath: string, isLogo?: boolean): string {
    if (isLogo && !relativePath) return '/assets/images/nologo.jpg';
    if (!relativePath) return '/assets/images/store_shop_banner.png';
    return relativePath;
  }

  getProductNatureNameById(id: string) {
    const category = this.productNatures.find((x) => x.id === id);
    if (!category) return '';
    return category.category;
  }

  parseStreamStatus(
    statusEnumValue: StreamStatusEnum,
    startTime: Date
  ): string {
    switch (statusEnumValue) {
      case StreamStatusEnum.IN_PROGRESS:
        return 'On Air';
      case StreamStatusEnum.PENDING:
        return this.datePipe.transform(startTime, 'hh:mm a MMM yy');
    }
  }

  hasInProgressStatus(statusEnumValue: StreamStatusEnum): boolean {
    return statusEnumValue == StreamStatusEnum.IN_PROGRESS;
  }

  parseSessionUrlPath(sessionType: string) {
    if (sessionType.toLowerCase().indexOf('showcase') > -1) return 'sessions';

    return 'sessions/exclusive';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  displayProject(project: IProject) {
    if (_.isEmpty(this.authenticationService.currentUserId)) {
      return this.router.navigate(['/login']);
    }

    const dialogRef = this.dialog.open(ProjectDisplayDialogComponent, {
      data: {
        projectId: project._id,
        actionType:
          project.type === CreatorProjectTypeEnum.RETAILER_PROJECT
            ? PROJECT_DISPLAY_TYPE_ENUM.VIEW
            : PROJECT_DISPLAY_TYPE_ENUM.RETAILER_FOR_INVITATION,
      },
    });
    dialogRef.afterClosed().toPromise();
  }
}
