import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { NotificationStatusEnum } from '../enums/notification.enum';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  protected apiServer = AppConfig.settings.apiServer;
  protected localStorageKeys = AppConfig.settings.localStorage.keys;

  constructor(private http: HttpClient) {}

  createNotification(notificationData: {
    retailerId: string;
    creatorId: string;
    productId: string;
    referenceProjectId?: string;
    status:
      | NotificationStatusEnum.INVITED
      | NotificationStatusEnum.ACCEPTED
      | NotificationStatusEnum.PAID
      | NotificationStatusEnum.REJECTED;
  }): Observable<any> {
    return this.http
      .post<any>(
        `${this.apiServer.basepath}/notification/create`,
        notificationData
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }

  getNotifications() {
    return this.http
      .get(`${this.apiServer.basepath}/notifications/all`)
      .pipe(map((res) => res));
  }

  markNotificationSeen(notificationId: string) {
    return this.http
      .get(`${this.apiServer.basepath}/notifications/seen/${notificationId}`)
      .pipe(map((res) => res));
  }

  markAllNotificationSeen() {
    return this.http
      .get(`${this.apiServer.basepath}/notifications/makeSeen/all`)
      .pipe(map((res) => res));
  }

  getUnseenNotificationsCount() {
    return this.http
      .get(`${this.apiServer.basepath}/notifications/unseen/count`)
      .pipe(map((res) => res));
  }

  getSpecificNotifications(notificationStatus: NotificationStatusEnum) {
    return this.http
      .get(
        `${this.apiServer.basepath}/notifications/specificStatus/${notificationStatus}`
      )
      .pipe(map((res) => res));
  }
}
