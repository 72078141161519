import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { IMessageResponse } from '../models/message.mode';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  protected apiServer = AppConfig.settings.apiServer;
  protected localStorageKeys = AppConfig.settings.localStorage.keys;

  constructor(private http: HttpClient) {}

  getAllMessages(): Observable<IMessageResponse[]> {
    const apiUrl = `${this.apiServer.basepath}/messages/getAll`;
    return this.http.get(apiUrl).pipe(
      map((res: any) => {
        if (res.isSuccess) {
          return res.messages as IMessageResponse[];
        }
        throw Error('Failed to get the message');
      })
    );
  }

  getMessageById(messageId: string): Observable<IMessageResponse> {
    const apiUrl = `${this.apiServer.basepath}/messages/getById/${messageId}`;
    return this.http.get(apiUrl).pipe(
      map((res: any) => {
        if (res.isSuccess) {
          return res.message as IMessageResponse;
        }
        throw Error('Failed to get the message');
      })
    );
  }
}
