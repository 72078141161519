import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  AccountService,
  AuthenticationService,
  ItemService,
} from 'src/app/services';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import _ from 'lodash';

import { RetailerStoreService } from 'src/app/services/retailer-store.service';
import {
  CreatorProjectTypeEnum,
  IProject,
  ProjectStatusEnum,
} from 'src/app/models/project.model';
import { ProjectService } from 'src/app/services/project.service';
import { HttpErrorResponse } from '@angular/common/http';
import { IMessageResponse } from 'src/app/models/message.mode';
import { MessageService } from 'src/app/services/message.service';

@Component({
  selector: 'project-form-dialog',
  templateUrl: '../../views/account/project-form.dialog.component.html',
  styleUrls: [],
})
export class ProjectFormDialogComponent implements OnInit {
  public Editor = ClassicEditor;

  inProgress: boolean = false;
  isCreating: boolean = true;
  showcaseProjectType = CreatorProjectTypeEnum.SHOWCASE_PROJECT;
  retailerProjectType = CreatorProjectTypeEnum.RETAILER_PROJECT;

  projectType: FormControl;
  name: FormControl;
  description: FormControl;
  thumbnail = File;
  video = File;
  status: FormControl;
  price: FormControl;
  selectedRetailerProductId: FormControl;

  thumbnailIsInvalid = false;
  videoIsInvalid = false;

  projectForm: FormGroup;

  retailerProducts: Array<IMessageResponse> = [];

  existingProject: IProject;

  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ProjectFormDialogComponent>,
    public itemService: ItemService,
    public authService: AuthenticationService,
    public accountService: AccountService,
    public retailerStoreService: RetailerStoreService,
    private projectService: ProjectService,
    private messageService: MessageService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.existingProject = data;
    this.isCreating = _.isEmpty(_.get(this.existingProject, '_id'));
  }

  ngOnInit(): void {
    this.loadRetailerProjects();
  }

  loadFormData() {
    const projectType = _.get(
      this.existingProject,
      'type',
      CreatorProjectTypeEnum.SHOWCASE_PROJECT
    );

    const selectedRetailerProduct = _.find(
      this.retailerProducts,
      (p) => p.productId === _.get(this.existingProject, 'productId') || ''
    );

    this.projectType = new FormControl(projectType, [Validators.required]);
    this.name = new FormControl(_.get(this.existingProject, 'name', ''), [
      Validators.required,
    ]);
    this.description = new FormControl(
      _.get(this.existingProject, 'description', ''),
      [Validators.required]
    );
    this.status = new FormControl(
      _.get(this.existingProject, 'status', ProjectStatusEnum.DRAFT_PROJECT),
      [Validators.required]
    );
    this.price = new FormControl(_.get(this.existingProject, 'price', 0), [
      Validators.required,
    ]);
    this.selectedRetailerProductId = new FormControl(
      _.get(this.existingProject, 'messageId', '')
    );

    this.projectForm = this.formBuilder.group({
      projectType: this.projectType,
      name: this.name,
      description: this.description,
      thumbnail: new FormControl('', []),
      video: new FormControl('', []),
      status: this.status,
      price: this.price,
      selectedRetailerProductId: selectedRetailerProduct?._id || '',
    });

    // set project type explicitly
    this.projectForm
      .get('projectType')
      .setValue(
        _.get(
          this.existingProject,
          'type',
          CreatorProjectTypeEnum.SHOWCASE_PROJECT
        )
      );

    // set selected retailer product explicitly
    this.projectForm.get('projectType').setValue(projectType);
    // this.projectForm
    //   .get('selectedRetailerProductId')
    //   .setValue(selectedRetailerProduct._id);

    this.onFormDataChanges();
  }

  onFormDataChanges() {
    this.projectForm
      .get('projectType')
      .valueChanges.subscribe((projectType) => {
        if (projectType === this.showcaseProjectType) {
          this.projectForm.controls['price'].setValidators([
            Validators.required,
          ]);
          this.projectForm.controls[
            'selectedRetailerProductId'
          ].clearValidators();
        } else {
          this.projectForm.controls['price'].clearValidators();
          this.projectForm.controls['selectedRetailerProductId'].setValidators([
            Validators.required,
          ]);
        }
      });

    // video and thumbnail is not required for update operation
    if (_.isEmpty(_.get(this.existingProject, 'id'))) {
      this.projectForm.controls['price'].setValidators([Validators.required]);
    } else {
      this.projectForm.controls['price'].setValidators([Validators.required]);
    }
  }

  loadRetailerProjects() {
    this.inProgress = true;
    this.messageService
      .getAllMessages()
      .toPromise()
      .then((res: any) => {
        this.retailerProducts = _.get(res, 'messages', []);
        this.loadFormData();
      })
      .catch((err: HttpErrorResponse) => {
        const errorDetail = err.error.error || err.error || err.statusText;
        this.snackBar.open(errorDetail, 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
      })
      .finally(() => {
        this.inProgress = false;
      });
  }

  onCancelClick() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.isCreating && _.get(this.thumbnail, 'name') === 'File') {
      this.thumbnailIsInvalid = true;
      return;
    }

    if (this.isCreating && _.get(this.video, 'name') === 'File') {
      this.videoIsInvalid = true;
      return;
    }

    const formData = new FormData();

    formData.append('name', this.name.value);
    formData.append('description', this.description.value);
    formData.append('type', this.projectType.value);
    formData.append('status', ProjectStatusEnum.DRAFT_PROJECT);
    formData.append('thumbnail', this.thumbnail as unknown as Blob);
    formData.append('video', this.video as unknown as Blob);

    // only for retailer project create operation
    if (this.projectType.value === CreatorProjectTypeEnum.RETAILER_PROJECT) {
      const productId = this.selectedRetailerProductId.value;
      const product = _.find(this.retailerProducts, (p) => p._id === productId);
      if (_.isEmpty(product)) {
        this.snackBar.open('Select a retailer product!', 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
        return;
      }
      formData.append('productId', product?.productId!);
      formData.append('referenceProjectId', product?.referenceProjectId!);
      formData.append('price', _.get(product, 'referenceProject.price'));
      formData.append('messageId', product?._id!);
      formData.append('retailerId', product?.retailerId!);
    } else {
      // for retailer project, price is predetermined
      // for showcase project, price have to define explicitly
      formData.append('price', this.price.value.toString());
    }

    this.inProgress = true;

    // create
    if (this.isCreating) {
      this.projectService
        .createProject(formData)
        .toPromise()
        .then((res) => {
          this.snackBar.open('New project is created', 'close', {
            duration: 10000,
            panelClass: ['mat-success-bg'],
          });
          this.dialogRef.close({ created: true, streamId: res.data.streamId });
        })
        .catch((err: HttpErrorResponse) => {
          const errorDetail = err.error.error || err.error || err.statusText;
          this.snackBar.open(errorDetail, 'Close', {
            duration: 10000,
            panelClass: ['mat-error-bg'],
          });
        })
        .finally(() => {
          this.inProgress = false;
        });
    } else {
      // update
      this.projectService
        .updateProject(this.existingProject._id, formData)
        .toPromise()
        .then((res) => {
          this.snackBar.open('Project is updated', 'close', {
            duration: 10000,
            panelClass: ['mat-success-bg'],
          });
          this.dialogRef.close({ created: true, streamId: res.data.streamId });
        })
        .catch((err: HttpErrorResponse) => {
          const errorDetail = err.error.error || err.error || err.statusText;
          this.snackBar.open(errorDetail, 'Close', {
            duration: 10000,
            panelClass: ['mat-error-bg'],
          });
        })
        .finally(() => (this.inProgress = false));
    }
  }

  onThumbnailChange($event) {
    if (_.size($event.target.files)) {
      const file = $event.target.files[0];
      this.thumbnail = file;
    } else {
      this.thumbnailIsInvalid = true;
    }
  }

  onVideoChange($event) {
    if (_.size($event.target.files)) {
      const file = $event.target.files[0];
      this.video = file;
    } else {
      this.videoIsInvalid = true;
    }
  }

  getErrorMessage(fieldKey: string) {
    switch (fieldKey) {
      case 'name':
        if (this.name.hasError('required')) return 'Project name is required';
        break;
      case 'description':
        if (this.description.hasError('required'))
          return 'Project description is required';
        break;
      case 'price':
        if (this.price.hasError('required')) return 'Project price is required';
        break;
      case 'projectType':
        if (this.projectType.hasError('required'))
          return 'Project type is required';
        break;
      case 'selectedRetailerProductId':
        if (this.selectedRetailerProductId.hasError('required'))
          return 'Retailer product id is required';
        break;
    }
  }
}
