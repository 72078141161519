import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import _ from 'lodash';
import { IMessageResponse } from 'src/app/models/message.mode';
import {
  CreatorProjectTypeEnum,
  IProject,
  ProjectStatusEnum,
} from 'src/app/models/project.model';
import { MessageService } from 'src/app/services/message.service';
import { ProjectService } from 'src/app/services/project.service';

@Component({
  selector: 'send-project-dialog',
  styleUrls: ['../../../assets/css/message/send-project.dialog.component.css'],
  templateUrl: '../../views/message/send-project.dialog.component.html',
})
export class SendProjectDialog implements OnInit {
  inProgress: boolean = false;

  name: FormControl;
  description: FormControl;
  thumbnail = File;
  video = File;

  thumbnailIsInvalid = false;
  videoIsInvalid = false;

  sendProjectForm: FormGroup;

  messageInfo: IMessageResponse;
  projectInfo: IProject;

  constructor(
    private messageService: MessageService,
    private projectService: ProjectService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<SendProjectDialog>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public selectedMessageId
  ) {}

  ngOnInit(): void {
    this.getMessageInfo();
  }

  loadFormData() {
    this.name = new FormControl(
      _.isEmpty(this.projectInfo) ? '' : _.get(this.projectInfo, 'name'),
      [Validators.required]
    );
    this.description = new FormControl(
      _.isEmpty(this.projectInfo) ? '' : _.get(this.projectInfo, 'description'),
      [Validators.required]
    );
    this.sendProjectForm = this.formBuilder.group({
      projectType: new FormControl(CreatorProjectTypeEnum.RETAILER_PROJECT, [
        Validators.required,
      ]),
      name: this.name,
      description: this.description,
      thumbnail: new FormControl('', []),
      video: new FormControl('', []),
      status: ProjectStatusEnum.DRAFT_PROJECT,
      // todo: set early
      price: 0,
      // todo: set early
      selectedRetailerProductId: new FormControl('', [Validators.required]),
    });
  }

  getMessageInfo() {
    this.messageService
      .getMessageById(this.selectedMessageId)
      .subscribe((res) => {
        this.messageInfo = res;
        // if there is no project, load empty form
        // if there is already a project, load the project in form
        if (!_.isEmpty(this.messageInfo.projectId)) {
          this.getProjectInfo();
        } else {
          this.loadFormData();
        }
      });
  }

  getProjectInfo() {
    this.projectService
      .getProjectById(this.messageInfo.projectId)
      .subscribe((res) => {
        this.projectInfo = res;
        this.loadFormData();
      });
  }

  getErrorMessage(fieldKey: string) {
    switch (fieldKey) {
      case 'name':
        if (this.name.hasError('required')) return 'Project name is required';
        break;
      case 'description':
        if (this.description.hasError('required'))
          return 'Project description is required';
        break;
    }
  }

  onThumbnailChange($event) {
    if (_.size($event.target.files)) {
      const file = $event.target.files[0];
      this.thumbnail = file;
    } else {
      this.thumbnailIsInvalid = true;
    }
  }

  onVideoChange($event) {
    if (_.size($event.target.files)) {
      const file = $event.target.files[0];
      this.video = file;
    } else {
      this.videoIsInvalid = true;
    }
  }

  onSubmit() {
    const formData = new FormData();

    formData.append('name', this.name.value);
    formData.append('description', this.description.value);
    formData.append('type', CreatorProjectTypeEnum.RETAILER_PROJECT);
    formData.append('status', ProjectStatusEnum.DRAFT_PROJECT);
    formData.append('thumbnail', this.thumbnail as unknown as Blob);
    formData.append('video', this.video as unknown as Blob);

    formData.append('productId', this.messageInfo.productId!);
    formData.append('referenceProjectId', this.messageInfo.referenceProjectId!);
    formData.append('price', this.messageInfo.referenceProject.price + '');
    formData.append('messageId', this.selectedMessageId);
    formData.append('retailerId', this.messageInfo.retailerId);

    this.inProgress = true;

    if (_.isEmpty(this.projectInfo)) {
      this.projectService
        .createProject(formData)
        .toPromise()
        .then((res) => {
          this.snackBar.open('New project is created', 'close', {
            duration: 10000,
            panelClass: ['mat-success-bg'],
          });
          this.dialogRef.close({ created: true, res });
        })
        .catch((err: HttpErrorResponse) => {
          const errorDetail = err.error.error || err.error || err.statusText;
          this.snackBar.open(errorDetail, 'Close', {
            duration: 10000,
            panelClass: ['mat-error-bg'],
          });
        })
        .finally(() => {
          this.inProgress = false;
        });
    } else {
      // update
      this.projectService
        .updateProject(this.projectInfo.id, formData)
        .toPromise()
        .then((res) => {
          this.snackBar.open('Project is updated', 'close', {
            duration: 10000,
            panelClass: ['mat-success-bg'],
          });
          this.dialogRef.close({ created: true, streamId: res.data.streamId });
        })
        .catch((err: HttpErrorResponse) => {
          const errorDetail = err.error.error || err.error || err.statusText;
          this.snackBar.open(errorDetail, 'Close', {
            duration: 10000,
            panelClass: ['mat-error-bg'],
          });
        })
        .finally(() => (this.inProgress = false));
    }
  }

  onCancelClick() {
    this.dialogRef.close();
  }
}
