<div class="pstn-relative" style="padding: 0 0 24px 0">
  <mat-icon
    style="
      right: 10px;
      top: 10px;
      background-color: white;
      border-radius: 50%;
      cursor: pointer;
    "
    class="pstn-absolute"
    (click)="cancelOperation()"
    >close</mat-icon
  >
  <img
    style="
      height: 300px;
      width: 400px;
      overflow: auto;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    "
    src="{{ productImagePath }}"
  />
  <div class="p-10" style="max-width: 400px">
    <div class="fs-16 fw-500" style="color: #0f0f0f">{{ productName }}</div>
    <!-- <div class="fs-12 fw-400">
      Bronation Hoops Gear has sent you and invitation to collaborate on this
      project
    </div> -->
  </div>
  <div
    *ngIf="showActions"
    mat-dialog-actions
    class="pstn-relative jc-fx-end pr-10"
  >
    <mat-spinner
      *ngIf="inProgress"
      diameter="25"
      class="color-primary pstn-absolute t24 l4"
    >
    </mat-spinner>
    <button
      disabled="{{ inProgress }}"
      mat-flat-button
      type="button"
      class="tt-upper mr-15 text-primary"
      (click)="rejectInvitation()"
    >
      <span>DECLINE</span>
    </button>
    <button
      disabled="{{ inProgress }}"
      mat-raised-button
      color="primary"
      type="submit"
      class="tt-upper"
      (click)="acceptInvitation()"
    >
      <span>ACCEPT</span>
    </button>
  </div>
</div>
