import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import _ from 'lodash';
import * as moment from 'moment';

import { NotificationService } from 'src/app/services/notification.service';
import { NotificationStatusEnum } from 'src/app/enums/notification.enum';
import { MatDialog } from '@angular/material/dialog';
import { IProduct } from 'src/app/models/product.model';
import { ProductDisplayDialogComponent } from '../common/product-display.dialog.component';
import { IUserInfo } from 'src/app/models/user-info.model';

export interface FormattedNotification {
  storeImage: string;
  storeName: string;
  productName: string;
  createdAt: string;
  status: string;
  projectId: string;
  product: IProduct;
  retailer: IUserInfo;
  creator: IUserInfo;
  statusFormatted: string;
  createdAtFormatted: string;
}

@Component({
  selector: 'invited-notification-item',
  styleUrls: ['../../../assets/css/header/notification-list.component.css'],
  template: `
    <span>
      <span class="bold-text">{{ notification.storeName }}</span>
      {{ notification.statusFormatted }} to collaborate on
      <span class="bold-text">{{ notification.productName }}</span> Product
    </span>
  `,
})
export class InvitedNotification implements OnInit {
  @Input()
  notification: FormattedNotification;

  constructor(public dialog: MatDialog) {}

  ngOnInit(): void {}

  openProductDisplayDialog(): void {
    const { product, retailer, creator } = this.notification;
    const dialogRef = this.dialog.open(ProductDisplayDialogComponent, {
      data: {
        productId: product.id,
        retailer,
        creator,
      },
    });
    dialogRef.afterClosed().toPromise();
  }
}

@Component({
  selector: 'paid-notification-item',
  styleUrls: ['../../../assets/css/header/notification-list.component.css'],
  template: `
    <span>
      <span class="bold-text">{{ notification.storeName }}</span>
      {{ notification.statusFormatted }} to for
      <span class="bold-text">{{ notification.productName }}</span> Product
    </span>
  `,
})
export class PaidNotification implements OnInit {
  @Input()
  notification: FormattedNotification;
  createdAt: string;

  constructor() {}

  ngOnInit(): void {
    this.notification.status = this.notification.status.toLowerCase();
    this.createdAt = moment(this.notification.createdAt).format('hh:MM A');
  }
}

@Component({
  selector: 'notification-list',
  styleUrls: ['../../../assets/css/header/notification-list.component.css'],
  templateUrl: '../../views/header/notification-list.component.html',
})
export class NotificationList implements OnInit {
  @Input()
  notificationList: Array<FormattedNotification> = [];
  showNotifications: boolean;

  constructor(
    private notificationService: NotificationService,
    private snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {
    this.showNotifications = false;
  }

  ngOnInit(): void {
    this.showNotifications = !!_.size(this.notificationList);
  }

  isPaidNotification(notificationStatus: string) {
    return (
      notificationStatus.toLowerCase() ===
      NotificationStatusEnum.PAID.toLowerCase()
    );
  }

  openProductDisplayDialog(notification): void {
    const { product, retailer, creator } = notification;
    const dialogRef = this.dialog.open(ProductDisplayDialogComponent, {
      data: {
        productId: product.id,
        retailer,
        creator,
      },
    });
    dialogRef.afterClosed().toPromise();
  }

  markNotificationSeen(notificationId: string) {
    this.notificationService
      .markNotificationSeen(notificationId)
      .toPromise()
      .then()
      .catch((err) => console.error(err));
  }
}
