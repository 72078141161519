<div class="">
  <div class="bg-f5f5f5 w-100 p-30 pl-sm-20 pr-sm-20 p-20-sm">
    <div class="d-flex-row w-100">
      <h1 class="h1-dark fs-22">Projects</h1>
      <button
        (click)="openCreateProjectDialog()"
        mat-raised-button
        color="primary"
        class="ml-auto-i mr-15"
      >
        Create Project
      </button>
    </div>
    <div class="d-inline-block d-flex-col-sm w-100 mt-20-i create-stream-res">
      <mat-form-field class="mr-10" appearance="outline">
        <input
          type="search"
          matInput
          placeholder="Search"
          [(ngModel)]="searchKeyword"
          (keyup)="filterProjectList()"
        />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
      <mat-form-field class="mr-10 mw-150px" appearance="outline">
        <mat-label>Project Type</mat-label>
        <mat-select
          (selectionChange)="filterProjectList()"
          [(ngModel)]="projectType"
        >
          <mat-option>All</mat-option>
          <mat-option value="{{ showcaseType }}">Showcase Project</mat-option>
          <mat-option value="{{ retailerType }}">Retailer Project</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-inline-block w-100-i">
      <div class="responsive-table">
        <table id="streamItems" class="w-100">
          <thead>
            <tr>
              <th class="pl-10-i">&nbsp;</th>
              <th class="pl-10-i">Name</th>
              <th class="pl-10-i">Type</th>
              <th class="pl-10-i">&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of tableDataSource">
              <td>
                <img
                  src="{{ row.thumbnail }}"
                  class="responsive-image mxw-52px mh-64px h-52px w-64px b-s1 brds-2px bc-00004"
                />
              </td>
              <td
                style="cursor: pointer"
                class="link-primary"
                (click)="displayProject(row._id)"
              >
                {{ row.name }}
              </td>
              <td class="text-0006 fs-14 lh-24">
                <span *ngIf="row.type == 'SHOWCASE_PROJECT'">showcase</span>
                <span *ngIf="row.type == 'RETAILER_PROJECT'">retailer</span>
              </td>
              <td>
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  aria-label="Example icon-button with a menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="displayProject(row._id)">
                    Open
                  </button>
                  <button mat-menu-item (click)="openUpdateProjectDialog(row)">
                    Update
                  </button>
                </mat-menu>
              </td>
            </tr>
            <tr
              class="mat-row"
              *ngIf="tableDataSource?.length === 0 && !projectListLoading"
            >
              <td class="mat-cell" colspan="9999">No data found!</td>
            </tr>
            <tr class="mat-row" *ngIf="projectListLoading">
              <td class="mat-cell" colspan="9999">
                <img
                  src="../../../assets/images/ajax-loader.gif"
                  alt="ajax-loader"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <mat-paginator
        (page)="pageChanged($event)"
        [length]="projectList.length"
        [pageSize]="tablePageSize"
        [pageSizeOptions]="[2, 5, 10, 25, 100]"
      ></mat-paginator>
    </div>
  </div>
</div>
