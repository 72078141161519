import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import _ from 'lodash';

import { CreatorProjectTypeEnum, IProject } from 'src/app/models/project.model';
import { ProjectService } from 'src/app/services/project.service';
import { ProjectFormDialogComponent } from './project-form.dialog.component';
import {
  PROJECT_DISPLAY_TYPE_ENUM,
  ProjectDisplayDialogComponent,
} from '../common/project-display.dialog.component';

@Component({
  selector: 'projects-page',
  templateUrl: '../../views/account/projects.component.html',
})
export class ProjectsComponent implements OnInit, OnDestroy {
  projectList: IProject[] = [];
  projectListBackup: IProject[] = [];
  projectListLoading: boolean;
  retailerType = CreatorProjectTypeEnum.RETAILER_PROJECT;
  showcaseType = CreatorProjectTypeEnum.SHOWCASE_PROJECT;
  projectType:
    | CreatorProjectTypeEnum.RETAILER_PROJECT
    | CreatorProjectTypeEnum.SHOWCASE_PROJECT;

  tablePageSize: number = 10;
  tablePageIndex: number = 0;
  tableDataSource: any[] = [];

  searchKeyword: string;

  constructor(
    private projectService: ProjectService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.loadProjectList();
  }

  loadProjectList(): void {
    this.projectListLoading = true;
    this.projectService
      .getProjects()
      .toPromise()
      .then((projectList: IProject[]) => {
        this.projectList = projectList;
        this.projectListBackup = projectList;
        const items = [...this.projectList];
        this.tableDataSource = items.splice(
          this.tablePageIndex * this.tablePageSize,
          this.tablePageSize
        );
        this.projectListLoading = false;
      });
  }

  pageChanged(event: PageEvent) {
    this.tablePageIndex = event.pageIndex;
    this.tablePageSize = event.pageSize;

    const items = [...this.projectList];
    this.tableDataSource = items.splice(
      this.tablePageIndex * this.tablePageSize,
      this.tablePageSize
    );
  }

  filterProjectList(): void {
    const self = this;
    let filterList = self.projectListBackup;

    if (!_.isEmpty(self.searchKeyword)) {
      filterList = _.filter(self.projectListBackup, (o) => {
        return Object.keys(o).some(
          (k) =>
            _.isString(o[k]) &&
            o[k]
              .toString()
              .toLowerCase()
              .includes(self.searchKeyword.toLowerCase())
        );
      });
    }

    if (!_.isEmpty(self.projectType)) {
      filterList = _.filter(filterList, (o) => {
        return o.type === self.projectType;
      });
    }

    const items = [...filterList];
    self.tableDataSource = items.splice(
      self.tablePageIndex * self.tablePageSize,
      self.tablePageSize
    );
  }

  openCreateProjectDialog(): void {
    const dialogRef = this.dialog.open(ProjectFormDialogComponent);
    dialogRef.afterClosed().subscribe((res) => {
      if (res && res.created) {
        // or redirect to the same page
        this.loadProjectList();
      }
    });
  }

  openUpdateProjectDialog(project: IProject): void {
    const dialogRef = this.dialog.open(ProjectFormDialogComponent, {
      data: project,
    });
    dialogRef
      .afterClosed()
      .toPromise()
      .then((res) => {
        location.reload();
      });
  }

  ngOnDestroy(): void {}

  displayProject(projectId: string) {
    const dialogRef = this.dialog.open(ProjectDisplayDialogComponent, {
      data: {
        projectId,
        actionType: PROJECT_DISPLAY_TYPE_ENUM.RETAILER_FOR_INVITATION,
      },
    });
    dialogRef.afterClosed().toPromise();
  }
}
