import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import _ from 'lodash';

import { AppConfig } from '../app.config';
import { RoleEnum } from '../enums/role.enum';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  protected apiServer = AppConfig.settings.apiServer;
  protected localStorageKeys = AppConfig.settings.localStorage.keys;

  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(
      AppConfig.currentUserObjectFromLocalStorage
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  get currentUserRole(): RoleEnum {
    const currentUserObj = AppConfig.currentUserObjectFromLocalStorage;
    if (
      currentUserObj &&
      currentUserObj.roles &&
      currentUserObj.roles.find((r: string) => r.toUpperCase() === 'RETAILER')
    ) {
      return RoleEnum.retailer;
    }

    return RoleEnum.consumer;
  }

  get isConsumer() {
    return _.findIndex(
      AppConfig?.currentUserObjectFromLocalStorage?.roles,
      (role) => role === 'CONSUMER'
    ) !== -1
      ? RoleEnum.consumer
      : undefined;
  }

  get isRetailer() {
    return _.findIndex(
      AppConfig?.currentUserObjectFromLocalStorage?.roles,
      (role) => role === 'RETAILER'
    ) !== -1
      ? RoleEnum.retailer
      : undefined;
  }

  get isCreator() {
    return _.findIndex(
      AppConfig?.currentUserObjectFromLocalStorage?.roles,
      (role) => role === 'CREATOR'
    ) !== -1
      ? RoleEnum.creator
      : undefined;
  }

  get currentUserId(): string {
    const currentUserObj = AppConfig.currentUserObjectFromLocalStorage;
    if (currentUserObj && currentUserObj.id) {
      return currentUserObj.id;
    }

    return undefined;
  }

  get currentUserLocationId(): string {
    const currentUserObj = AppConfig.currentUserObjectFromLocalStorage;
    if (currentUserObj && currentUserObj.locationId) {
      return currentUserObj.locationId;
    }

    return undefined;
  }

  get retailerDomain(): string {
    const currentUserObj = AppConfig.currentUserObjectFromLocalStorage;
    if (
      currentUserObj &&
      currentUserObj.roles &&
      currentUserObj.roles.find((r: string) => r.toUpperCase() === 'RETAILER')
    ) {
      return currentUserObj.domain;
    }

    return undefined;
  }

  get consumerDomain(): string {
    const currentUserObj = AppConfig.currentUserObjectFromLocalStorage;
    if (
      currentUserObj &&
      currentUserObj.roles &&
      currentUserObj.roles.find((r: string) => r.toUpperCase() === 'CONSUMER')
    ) {
      return currentUserObj.domain;
    }

    return undefined;
  }

  login(username: string, password: string) {
    return this.http
      .post<any>(`${this.apiServer.basepath}/users/login`, {
        email: username.toLowerCase(),
        password: password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          const encryptedUserData =
            AppConfig.getEncryptedUserDataByUserObject(user);
          localStorage.setItem(
            this.localStorageKeys.currentUser,
            encryptedUserData
          );
          this.currentUserSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.removeItem(this.localStorageKeys.currentUser);
    this.currentUserSubject.next(null);
  }

  refreshToken(refreshToken: string) {
    return this.http
      .post<any>(`${this.apiServer.basepath}/users/refresh-token`, {
        refreshToken: refreshToken,
      })
      .pipe(
        tap((tokens: any) => {
          let userCache = AppConfig.currentUserObjectFromLocalStorage;
          userCache.jwtToken = tokens.jwtToken;
          userCache.refreshToken = tokens.refreshToken;

          // update user local storage
          const encryptedUserData =
            AppConfig.getEncryptedUserDataByUserObject(userCache);
          localStorage.setItem(
            this.localStorageKeys.currentUser,
            encryptedUserData
          );
        })
      );
  }

  updateUserProductNaturesInLocalStorage(productNaturIds: string[]) {
    let userCache = AppConfig.currentUserObjectFromLocalStorage;
    userCache.productNatures = productNaturIds;

    // update user local storage
    const encryptedUserData =
      AppConfig.getEncryptedUserDataByUserObject(userCache);
    localStorage.setItem(this.localStorageKeys.currentUser, encryptedUserData);
  }

  updateUserLocationIdInLocalStorage(locationId: string) {
    let userCache = AppConfig.currentUserObjectFromLocalStorage;
    userCache.locationId = locationId;

    // update user local storage
    const encryptedUserData =
      AppConfig.getEncryptedUserDataByUserObject(userCache);
    localStorage.setItem(this.localStorageKeys.currentUser, encryptedUserData);
  }

  updateUserProfilePictureUrlInLocalStorage(profilePictureUrl: string) {
    let userCache = AppConfig.currentUserObjectFromLocalStorage;
    userCache.profilePictureUrl = profilePictureUrl;

    // update user local storage
    const encryptedUserData =
      AppConfig.getEncryptedUserDataByUserObject(userCache);
    localStorage.setItem(this.localStorageKeys.currentUser, encryptedUserData);
  }

  updateStoreLogoUrlInLocalStorage(logoUrl: string) {
    let userCache = AppConfig.currentUserObjectFromLocalStorage;
    userCache.logoUrl = logoUrl;
    // update user local storage
    const encryptedUserData =
      AppConfig.getEncryptedUserDataByUserObject(userCache);
    localStorage.setItem(this.localStorageKeys.currentUser, encryptedUserData);
  }
}
