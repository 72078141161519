import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { CreatorProjectTypeEnum, IProject } from 'src/app/models/project.model';
import {
  PROJECT_DISPLAY_TYPE_ENUM,
  ProjectDisplayDialogComponent,
} from './project-display.dialog.component';

@Component({
  selector: 'project-items-row',
  template: `
    <div
      *ngIf="projectItems.length > 0"
      id="storeFrontProjects"
      class="w-100 bg-F5F5F5"
    >
      <mat-card class="p-0-i">
        <mat-card-header
          class="d-block w-100 p-15 pb-0"
          fxLayout="row"
          fxLayoutAlign="space-between"
        >
          <mat-card-title class="h1-dark">
            <h3 class="m-0-i h1-dark">Projects</h3>
          </mat-card-title>
          <a routerLink="#" class="link-primary lh-23-i">View All</a>
        </mat-card-header>
        <mat-divider></mat-divider>
        <mat-card-content class="fx-wrap-sm p-5">
          <div
            class="d-flex-row fx-wrap-sm w-100 pstn-relative {{
              projectItems.length < 4 ? 'two-image-grid' : ''
            }}"
          >
            <div class="navigate-prev" (click)="displayPrevItems()">
              <mat-icon class="">arrow_back_ios</mat-icon>
            </div>
            <div class="navigate-next" (click)="displayNextItems()">
              <mat-icon class="">arrow_forward_ios</mat-icon>
            </div>
            <mat-card
              class="m-5 d-flex-col flex-auto p-0-i slider-card-item"
              *ngFor="let item of projectItems; let i = index"
              [class.hidden]="!indexBetweenRange(i)"
              [class.active]="indexBetweenRange(i)"
            >
              <span
                class="bg-primary fs-8 fw-500 brds-50px text-white ff-roboto p-2px-8px h-fit-content"
                style="position: absolute; top: 4px; left: 4px; z-index: 99;"
                >{{
                  item.type === 'SHOWCASE_PROJECT' ? 'SHOWCASE' : 'RETAILER'
                }}</span
              >
              <a
                class="d-flex pstn-relative m-0-i w-100-i lb-card-img-contaier"
              >
                <img
                  class="mb-0-i lb-card-img"
                  [src]="item.thumbnail"
                  onerror="this.src='./assets/images/thumbnail-default.jpg'"
                  (click)="openProjectDisplayDialog(item)"
                />
              </a>
              <mat-card-content class="mb-50-i p-15-i">
                <span class="fs-10 fw-600 text-0004 ff-roboto">{{
                  item.created
                    | date
                      : 'dd MMM hh:mm
                              a'
                }}</span>
                <a
                  class="p3-dark-fwm mt-10-i fs-13-i"
                  (click)="openProjectDisplayDialog(item)"
                >
                  <h5 class="p3-dark-fwm mt-10-i fs-13-i">
                    {{ item.name }}
                  </h5>
                </a>
              </mat-card-content>
              <mat-card-footer
                class="p-15-i d-block of-hidden w-100 ml-0-i pstn-absolute-b16"
              >
                <p class="m-0-i">
                  <a
                    class="d-flex-row"
                    href="/shops/{{ item?.creator?.domain }}"
                  >
                    <img
                      [src]="item?.creator?.logoUrl | apiImgPath : { nl: true }"
                      class="w-36px-i h-36px-i brds-100 b-s1 bc-00012"
                      onerror="this.src='./assets/images/nologo.jpg'"
                    />
                    <span
                      class="fs-14 fw-500 text-primary ff-roboto lh-30 ml-15-i wp-nowrap"
                      >{{ item?.creator.storeName }}</span
                    >
                    <span class="fs-14 fw-500 ff-roboto lh-30 ml-15-i wp-nowrap"
                      >$ {{ item?.price }}</span
                    >
                  </a>
                </p>
              </mat-card-footer>
            </mat-card>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  `,
})
export class ProjectItemsRowComponent implements OnInit {
  @Input()
  retailerDomain: string;
  @Input()
  projectItems: Array<IProject>;

  startIndex: number = 0;
  endIndex: number = 3;

  constructor(
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  displayNextItems() {
    if (this.endIndex + 1 > this.projectItems.length) {
      this.startIndex = 0;
      this.endIndex = 3;
      return;
    }

    this.startIndex = this.startIndex + 1;
    this.endIndex = this.endIndex + 1;
  }

  displayPrevItems() {
    if (this.startIndex - 1 < 0) {
      this.startIndex = this.projectItems.length - 4;
      this.endIndex = this.projectItems.length - 1;
      return;
    }

    this.startIndex = this.startIndex - 1;
    this.endIndex = this.endIndex - 1;
  }

  indexBetweenRange(index) {
    if (index >= this.startIndex && index <= this.endIndex) {
      return true;
    }

    return false;
  }

  openProjectDisplayDialog(project: IProject): void {
    const dialogRef = this.dialog.open(ProjectDisplayDialogComponent, {
      data: {
        projectId: project.id || project._id,
        actionType:
          project.type === CreatorProjectTypeEnum.RETAILER_PROJECT
            ? PROJECT_DISPLAY_TYPE_ENUM.VIEW
            : PROJECT_DISPLAY_TYPE_ENUM.RETAILER_FOR_INVITATION,
      },
    });
    dialogRef.afterClosed().toPromise();
  }
}
