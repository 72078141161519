import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import _ from 'lodash';

import { AppConfig } from 'src/app/app.config';
import { IProject } from 'src/app/models/project.model';
import { AccountService } from 'src/app/services';
import { ProjectService } from 'src/app/services/project.service';
import { SquaurePaymentService } from 'src/app/services/square-payment.service';

@Component({
  selector: 'project-payment-dialog',
  templateUrl: '../../views/message/project-payment.dialog.component.html',
})
export class ProjectPaymentDialog implements OnInit, AfterViewInit {
  inProgress: boolean;
  projectId: string;
  projectInfo: IProject;
  paymentCard: any;
  appId: string;
  locationId: string;
  window: Window;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    @Inject(DOCUMENT) private document,
    public dialogRef: MatDialogRef<ProjectPaymentDialog>,
    private projectService: ProjectService,
    private paymentService: SquaurePaymentService,
    private accountService: AccountService,
    private snackBar: MatSnackBar
  ) {
    this.projectId = data.projectId;
    this.window = this.document.defaultView;
    this.inProgress = false;
  }

  ngOnInit(): void {
    this.appId = AppConfig.settings.square.appId;
    this.fetchRequiredData();
  }

  async ngAfterViewInit() {
    var self = this;
    var documentWindow = self?.window;
    const squareSdk = _.get(documentWindow, 'Square');
    if (_.isEmpty(squareSdk)) {
      throw new Error('Square.js failed to load properly');
    }

    const payments = squareSdk.payments(self.appId, self.locationId);

    try {
      this.paymentCard = await self.paymentService.initializeCard(payments);
      console.log('paymentCard');
      console.log(this.paymentCard);
    } catch (e) {
      console.error('Initializing Card failed', e);
      return;
    }
  }

  fetchRequiredData() {
    console.log('this.projectId');
    console.log(this.projectId);
    this.projectService.getProjectById(this.projectId).subscribe((res) => {
      this.projectInfo = res;
      console.log('this.projectInfo');
      console.log(this.projectInfo);
      this.accountService
        .getUserInfoByUserId(res.creatorId)
        .subscribe((res) => {
          this.locationId = res.locationId;
        });
    });
  }

  makePayment() {
    this.inProgress = true;
    this.paymentService
      .tokenize(this.paymentCard)
      .then((token) => {
        this.projectService
          .makePayment(this.projectId, token)
          .subscribe((res) => {
            console.log('res');
            console.log(res);
          });
        this.inProgress = false;
        this.snackBar.open('Payment successful!', 'Close', {
          duration: 10000,
          panelClass: ['mat-primary-bg'],
        });
        this.closeDialog();
      })
      .catch((err) => {
        console.error(err);
        this.inProgress = false;
        const errorDetail =
          err?.error?.error ||
          err.error ||
          err.statusText ||
          'Error in making payment!';
        this.snackBar.open(errorDetail, 'Close', {
          duration: 10000,
          panelClass: ['mat-error-bg'],
        });
      });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
