<div class="mw-580px mnw-320px">
  <form
    name="sendProjectForm"
    [formGroup]="sendProjectForm"
    (ngSubmit)="onSubmit()"
    method="post"
    enctype="multipart/form-data"
    *ngIf="sendProjectForm"
  >
    <label class="p3-dark-fwm mb-15 d-block text-black"
      >Project Information</label
    >
    <div class="">
      <div class="form-item mt-15">
        <div class="row d-block">
          <div class="col-12">
            <mat-form-field appearance="outline" class="d-block-i">
              <mat-label>Project Name</mat-label>
              <input
                matInput
                placeholder="Project Name"
                [formControl]="name"
                required
              />
              <mat-error *ngIf="name.invalid"
                >{{ getErrorMessage("name") }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row d-block pt-5">
          <div class="col-12">
            <mat-form-field appearance="outline" class="d-block-i">
              <mat-label>Project Description</mat-label>
              <input
                matInput
                placeholder="Description"
                [formControl]="description"
                required
              />
              <mat-error *ngIf="description.invalid"
                >{{ getErrorMessage("description") }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row d-flex mt-25">
          <div class="col-12">
            <div class="form-item">
              <label class="p3-dark-fwm mb-15 d-block text-black"
                >Thumbnail</label
              >
              <div class="p-15 bg-blk-004 pstn-relative">
                <input
                  id="thumbnail"
                  class="mat-stroke-button-file-input"
                  type="file"
                  (change)="onThumbnailChange($event)"
                  required
                  accept="image/*"
                />
                <div
                  class="mat-form-field-subscript-wrapper pstn-relative-i"
                  [class.hidden]="!thumbnailIsInvalid"
                >
                  <p class="mat-error m-0-i">Thumbnail is required</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex mt-25">
          <div class="col-12">
            <div class="form-item">
              <label class="p3-dark-fwm mb-15 d-block text-black">Video</label>
              <div class="p-15 bg-blk-004 pstn-relative">
                <input
                  id="video"
                  class="mat-stroke-button-file-input"
                  type="file"
                  (change)="onVideoChange($event)"
                  accept="video/mp4,video/x-m4v,video/*"
                  required
                />
                <div
                  class="mat-form-field-subscript-wrapper pstn-relative-i"
                  [class.hidden]="!videoIsInvalid"
                >
                  <p class="mat-error m-0-i">Video is required</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="mat-dialog-actions pstn-relative m-0-24-neg p-0-24-i bt-s1-i bc-00012-i mt-15"
    >
      <mat-spinner
        diameter="25"
        class="color-primary pstn-absolute t24 l4"
        [class.hidden]="!inProgress"
      >
      </mat-spinner>
      <button
        mat-button
        class="tt-upper text-primary ml-auto-i"
        (click)="onCancelClick()"
      >
        <span>Cancel</span>
      </button>
      <button
        mat-raised-button
        color="primary"
        type="submit"
        class="tt-upper ml-0-i"
        disabled="{{ inProgress }}"
      >
        <span>Send</span>
      </button>
    </div>
  </form>
</div>
