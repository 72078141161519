import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import _ from 'lodash';

import { MessageService } from 'src/app/services/message.service';
import { IMessageResponse } from 'src/app/models/message.mode';

@Component({
  selector: 'message-list',
  styleUrls: ['../../../assets/css/message/message-list.component.css'],
  templateUrl: '../../views/message/message-list.component.html',
})
export class MessageListComponent implements OnInit, OnDestroy {
  @Input() selectMessageId: (messageId: string) => void;

  selectedMessageId: string;
  messageItems = [];
  filteredMessageItems = [];
  showMessageList = false;

  messageSearchText: string;

  constructor(private messageService: MessageService) {}

  ngOnInit(): void {
    this.messageService.getAllMessages().subscribe((res) => {
      this.messageItems = res;
      this.filteredMessageItems = this.messageItems;
      this.showMessageList = !!this.filteredMessageItems.length;
      this.messageSearchText = '';
    });
  }

  ngOnDestroy(): void {}

  onSelectMessage(messageItem: IMessageResponse) {
    this.selectedMessageId = messageItem._id;
    this.selectMessageId(messageItem._id);
  }

  onMessageSearchTextChange(messageSearchText) {
    this.messageSearchText = messageSearchText;
    if (!_.size(this.messageSearchText)) {
      this.filteredMessageItems = this.messageItems;
      return;
    }

    this.filteredMessageItems = _.filter(this.messageItems, (messageItem) => {
      const messageTitle = _.lowerCase(_.get(messageItem, 'name', ''));
      const projectName = _.lowerCase(
        _.get(messageItem, 'referenceProject.name', '')
      );
      const retailerFirstName = _.lowerCase(
        _.get(messageItem, 'retailer.firstName', '')
      );
      const retailerLastName = _.lowerCase(
        _.get(messageItem, 'retailer.lastName', '')
      );
      const retailerStoreName = _.lowerCase(
        _.get(messageItem, 'retailer.storeName', '')
      );
      const creatorFirstName = _.lowerCase(
        _.get(messageItem, 'creator.firstName', '')
      );
      const creatorLastName = _.lowerCase(
        _.get(messageItem, 'creator.lastName', '')
      );
      const creatorStoreName = _.lowerCase(
        _.get(messageItem, 'creator.storeName', '')
      );

      const lowerCaseSearchText = _.lowerCase(messageSearchText);

      if (
        messageTitle.includes(lowerCaseSearchText) ||
        projectName.includes(lowerCaseSearchText) ||
        retailerFirstName.includes(lowerCaseSearchText) ||
        retailerLastName.includes(lowerCaseSearchText) ||
        retailerStoreName.includes(lowerCaseSearchText) ||
        creatorFirstName.includes(lowerCaseSearchText) ||
        creatorLastName.includes(lowerCaseSearchText) ||
        creatorStoreName.includes(lowerCaseSearchText)
      ) {
        return true;
      }
      return false;
    });
  }
}
