import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { IConversation } from '../models/conversession.model';
import { ConversationTypeEnum } from '../enums/conversation-enum';

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  protected apiServer = AppConfig.settings.apiServer;
  protected localStorageKeys = AppConfig.settings.localStorage.keys;

  constructor(private http: HttpClient) {}

  getConversationsByMessageId(messageId: string): Observable<IConversation[]> {
    const apiUrl = `${this.apiServer.basepath}/conversation/getByMessageId/${messageId}`;
    return this.http.get(apiUrl).pipe(
      map((res: any) => {
        if (res.isSuccess) {
          return res.data as IConversation[];
        }
        throw Error('Failed to get the message');
      })
    );
  }

  addConversation(conversationData: {
    messageId: string;
    senderId: string;
    receiverId: string;
    type: ConversationTypeEnum;
    content: string;
  }) {
    const apiUrl = `${this.apiServer.basepath}/conversation/addConversation`;
    return this.http.post(apiUrl, conversationData).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  getUnseenConversationCountByMessageId(messageId) {
    const apiUrl = `${this.apiServer.basepath}/conversation/getAllUnseenConversationCountByMessageId/${messageId}`;
    return this.http.get(apiUrl).pipe(
      map((res: any) => {
        if (res.isSuccess) {
          return res.data.count as number;
        }
        throw Error('Failed to get the message');
      })
    );
  }

  getAllUnseenConversationCount() {
    const apiUrl = `${this.apiServer.basepath}/conversation/getAllUnseenConversationCount`;
    return this.http.get(apiUrl).pipe(
      map((res: any) => {
        if (res.isSuccess) {
          return res.data.count as number;
        }
        throw Error('Failed to get the message');
      })
    );
  }
}
