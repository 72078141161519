<div>
  <div class="p-15" *ngIf="!showNotifications">No notifications found!</div>
  <span *ngIf="showNotifications">
    <div class="pl-10 pr-10" *ngFor="let notification of this.notificationList">
      <div
        class="d-flex"
        [ngStyle]="{
          opacity: notification.isSeen ? 0.5 : 1
        }"
      >
        <img
          class="pr-5"
          style="border-radius: 50%; width: 50px; height: 38px"
          src="{{ notification.storeImage }}"
        />
        <div>
          <div
            style="cursor: pointer"
            mat-menu-content
            class="fs-14 lh-24 fw-400 d-inline d-flex-col"
            (click)="markNotificationSeen(notification._id)"
          >
            <paid-notification-item
              *ngIf="isPaidNotification(notification.status)"
              [notification]="notification"
            ></paid-notification-item>
            <invited-notification-item
              *ngIf="!isPaidNotification(notification.status)"
              [notification]="notification"
              (click)="openProductDisplayDialog(notification)"
            ></invited-notification-item>
          </div>
          <div class="created-at fs-12 fw-500">
            {{ notification.createdAtFormatted }}
          </div>
        </div>
      </div>
    </div>
  </span>
</div>
